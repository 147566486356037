/** @jsxImportSource @emotion/react */
import { useSelector } from "@xstate/react";
import { Button, ButtonGroup, H4, H5 } from "@blueprintjs/core";
import { css } from "@emotion/react";

import { ReviewMachineState } from "../../stateMachines/review.machine";
import { useReviewStateContext } from "../../utils/reviewState";
import { SeverityScore } from "@pham740/react-pdf-highlighter";

const selectionSelector = (state: ReviewMachineState) => {
  return state.context.selection;
};
const sentencesSelector = (state: ReviewMachineState) => {
  return state.context.sentences;
};
const curPageNumberSelector = (state: ReviewMachineState) => {
  return state.context.curPageNumber;
};
const curSentenceNumberSelector = (state: ReviewMachineState) => {
  return state.context.curSentenceNumber;
};

export const HighlightCard: React.FC = () => {
  const { reviewService } = useReviewStateContext();
  const { send } = reviewService;
  const selection = useSelector(reviewService, selectionSelector);
  const sentences = useSelector(reviewService, sentencesSelector);
  const curPageNumber = useSelector(reviewService, curPageNumberSelector);
  const curSentenceNumber = useSelector(
    reviewService,
    curSentenceNumberSelector
  );

  const renderText = () => {
    return (
      <>
        <p>Highlighted phrase:</p>
        <H4>{selection?.text}</H4>
        <br />
      </>
    );
  };

  const renderSeverityScore = () => {
    return (
      <>
        <p>How severe is the risk in the above statement?</p>
        <ButtonGroup fill>
          <Button
            text="High"
            active={
              sentences[curPageNumber][curSentenceNumber]["info"][
                "severity_score"
              ] === SeverityScore.HIGH
            }
            onClick={() => {
              send({
                type: "SET_SEVERITY_SCORE",
                id: sentences[curPageNumber][curSentenceNumber]["id"],
                severity_score: SeverityScore.HIGH,
              });
            }}
          />
          <Button
            text="Medium"
            active={
              sentences[curPageNumber][curSentenceNumber]["info"][
                "severity_score"
              ] === SeverityScore.MEDIUM
            }
            onClick={() => {
              send({
                type: "SET_SEVERITY_SCORE",
                id: sentences[curPageNumber][curSentenceNumber]["id"],
                severity_score: SeverityScore.MEDIUM,
              });
            }}
          />
          <Button
            text="Low"
            active={
              sentences[curPageNumber][curSentenceNumber]["info"][
                "severity_score"
              ] === SeverityScore.LOW
            }
            onClick={() => {
              send({
                type: "SET_SEVERITY_SCORE",
                id: sentences[curPageNumber][curSentenceNumber]["id"],
                severity_score: SeverityScore.LOW,
              });
            }}
          />
        </ButtonGroup>
      </>
    );
  };

  const renderEditButtons = () => {
    return (
      <ButtonGroup css={editStyles}>
        {selection?.text && selection?.severity_score && (
          <Button
            icon="confirm"
            minimal={true}
            text="SAVE"
            onClick={() => {
              send({ type: "SAVE_HIGHLIGHT" });
              send({ type: "TOGGLE" });
            }}
          />
        )}
        <Button
          icon="delete"
          minimal={true}
          text="CANCEL"
          onClick={() => {
            if (curSentenceNumber === 0 && !selection?.severity_score) {
              send({
                type: "CANCEL_HIGHLIGHT",
              });
            } else {
              send({
                type: "CANCEL_HIGHLIGHT",
                id: sentences[curPageNumber][curSentenceNumber]["id"],
              });
            }
            send({ type: "TOGGLE" });
          }}
        />
      </ButtonGroup>
    );
  };

  return (
    <div css={divStyles}>
      {!selection?.text && !selection?.severity_score && (
        <H5 css={helpTextStyles}>
          Highlight additional problematic phrases in the PDF viewer
        </H5>
      )}
      {selection?.text && renderText()}
      {selection?.text && selection?.severity_score && renderSeverityScore()}
      {selection?.text && renderEditButtons()}
    </div>
  );
};

const divStyles = css`
  width: 100%;
  padding: 24px;
`;

const editStyles = css`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`;

const helpTextStyles = css`
  display: flex;
  justify-content: center;
`;
