import React from "react";
import { useApolloClient } from "@apollo/client";
import { useInterpret } from "@xstate/react";
import { createContext, HTMLProps, useContext } from "react";

import { authMachine, AuthMachineActor } from "../stateMachines/auth.machine";
import {
  unsavedMachine,
  UnsavedMachineActor,
} from "../stateMachines/unsaved.machine";

interface Props extends HTMLProps<HTMLDivElement> {}

export const AppStateContext = createContext<{
  authService: AuthMachineActor;
  unsavedService: UnsavedMachineActor;
  // @ts-expect-error
}>({});

export const AppStateProvider: React.FC<Props> = ({ ...props }) => {
  const client = useApolloClient();
  const authService = useInterpret(authMachine, { context: { client } });
  const unsavedService = useInterpret(unsavedMachine);

  return (
    <AppStateContext.Provider value={{ authService, unsavedService }}>
      {!!authService && !!unsavedService && props.children}
    </AppStateContext.Provider>
  );
};

export const useAppStateContext = () => {
  const context = useContext(AppStateContext);

  if (!context) {
    throw new Error(
      "useAppStateContext must be used within the AppStateContext.Provider"
    );
  }

  return context;
};
