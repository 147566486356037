/** @jsxImportSource @emotion/react */
import { useSelector } from "@xstate/react";

import { UploadMachineState } from "..//stateMachines/upload.machine";
import { useUploadStateContext } from "../utils/uploadState";
import { ReviewStateProvider } from "../utils/reviewState";

import { ReviewLayout } from "../components/layout/ReviewLayout";

const docIdSelector = (state: UploadMachineState) => {
  const docId = state.context.uploadedDoc?.document_id
    ? state.context.uploadedDoc?.document_id
    : state.context.savedDoc?.document_id;
  return docId;
};
const pageUrlsSelector = (state: UploadMachineState) => {
  return state.context.pageUrls;
};
const allSentencesSelector = (state: UploadMachineState) => {
  return state.context.allSentences;
};
const sentencesSelector = (state: UploadMachineState) => {
  return state.context.sentences;
};
const autoSaveTimeSelector = (state: UploadMachineState) => {
  return state.context.savedDoc?.creation_time
    ? state.context.savedDoc?.creation_time
    : new Date();
};

interface ReviewViewProps {
  css?: any;
}

export const ReviewView: React.FC<ReviewViewProps> = () => {
  const { uploadService } = useUploadStateContext();
  const docId = useSelector(uploadService, docIdSelector);
  const pageUrls = useSelector(uploadService, pageUrlsSelector);
  const allSentences = useSelector(uploadService, allSentencesSelector);
  const sentences = useSelector(uploadService, sentencesSelector);
  const autoSaveTime = useSelector(uploadService, autoSaveTimeSelector);

  return (
    <ReviewStateProvider
      docId={docId}
      isReviewing={true}
      pageUrls={pageUrls}
      allSentences={allSentences}
      sentences={sentences}
      autoSaveTime={autoSaveTime}
    >
      <ReviewLayout />
    </ReviewStateProvider>
  );
};
