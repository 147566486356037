import { ActorRefFrom, StateFrom, actions, createMachine } from "xstate";

type UnsavedContext = {
  unsavedChanges: boolean;
};

type UnsavedEvents = { type: "SET_UNSAVED_CHANGES"; status: boolean };

const getInitialState = (): UnsavedContext => ({
  unsavedChanges: false,
});

export type UnsavedMachineActor = ActorRefFrom<typeof unsavedMachine>;
export type UnsavedMachineState = StateFrom<typeof unsavedMachine>;

export const unsavedMachine = createMachine(
  {
    tsTypes: {} as import("./unsaved.machine.typegen").Typegen0,
    schema: {
      context: {} as UnsavedContext,
      events: {} as UnsavedEvents,
    },
    context: { ...getInitialState() },
    initial: "alive",
    states: {
      alive: {
        on: {
          SET_UNSAVED_CHANGES: {
            actions: "assignUnsavedChanges",
          },
        },
      },
    },
  },
  {
    actions: {
      assignUnsavedChanges: actions.assign((_ctx, event) => {
        return { unsavedChanges: event.status };
      }),
    },
  }
);
