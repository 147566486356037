/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Intent } from "@blueprintjs/core";
import { Button } from "@blueprintjs/core";
import { css } from "@emotion/react";

import { useUploadStateContext } from "../../utils/uploadState";
import { useReviewStateContext } from "../../utils/reviewState";
import { DocumentTabs } from "../document/DocumentTabs";
import { PdfViewer } from "../document/PdfViewer";

// const toaster = OverlayToaster.create({ position: "top", maxToasts: 1 });
interface PreviewLayoutProps {
  css?: any;
}

export const PreviewLayout: React.FC<PreviewLayoutProps> = () => {
  const { uploadService } = useUploadStateContext();
  const { send: uploadSend } = uploadService;

  const { reviewService } = useReviewStateContext();
  const { send: reviewSend } = reviewService;

  const { fileId } = useParams();
  const navigate = useNavigate();

  const [exitClicked, setExitClicked] = useState(false);

  // useEffect(() => {
  //   if (exitClicked) {
  //     showWarningToast();
  //   }
  // }, [exitClicked]);

  // const showWarningToast = () => {
  //   toaster.show({
  //     icon: "warning-sign",
  //     message:
  //       "Are you sure? Exiting now will remove the document, and you will need to upload a new document in its place.",
  //     timeout: 0,
  //     action: {
  //       text: "OK, exit anyways",
  //       onClick: onExitHandler,
  //     },
  //   });
  // };

  const onExitHandler = () => {
    // toaster.clear();
    reviewSend({ type: "CANCEL" });
    uploadSend({ type: "CANCEL" });
    navigate(`/`);
  };

  return (
    <div css={PreviewStyles}>
      <div className="column">
        <div css={ButtonStyles}>
          <Button
            text="Exit"
            onClick={() => {
              if (exitClicked) {
                onExitHandler();
              }
              setExitClicked(true);
            }}
          />
          <Button
            intent={Intent.PRIMARY}
            text="Start"
            onClick={() => {
              // toaster.clear();
              reviewSend({ type: "CONTINUE" });
              navigate(`/review/${fileId}`);
            }}
          />
        </div>
        <div className="row">
          <DocumentTabs
            pdfTab={<PdfViewer isHighlightable={false} type="pdf" />}
            textTab={<PdfViewer isHighlightable={false} type="text" />}
          />
        </div>
      </div>
    </div>
  );
};

const PreviewStyles = css`
  width: 100%;
  padding: 20px 60px;
  transform: translate(0%, -10);
`;

const ButtonStyles = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 12px;
`;
