/** @jsxImportSource @emotion/react */
import { useSelector } from "@xstate/react";

import { UploadMachineState } from "../stateMachines/upload.machine";
import { useUploadStateContext } from "../utils/uploadState";
import { ReviewStateProvider } from "../utils/reviewState";

import { PreviewLayout } from "../components/layout/PreviewLayout";

const docIdSelector = (state: UploadMachineState) => {
  return state.context.uploadedDoc?.document_id;
};
const pageUrlsSelector = (state: UploadMachineState) => {
  return state.context.pageUrls;
};
const allSentencesSelector = (state: UploadMachineState) => {
  return state.context.allSentences;
};
const sentencesSelector = (state: UploadMachineState) => {
  return state.context.sentences;
};

interface PreviewViewProps {
  css?: any;
}

export const PreviewView: React.FC<PreviewViewProps> = () => {
  const { uploadService } = useUploadStateContext();
  const docId = useSelector(uploadService, docIdSelector);
  const pageUrls = useSelector(uploadService, pageUrlsSelector);
  const allSentences = useSelector(uploadService, allSentencesSelector);
  const sentences = useSelector(uploadService, sentencesSelector);

  return (
    <ReviewStateProvider
      docId={docId}
      isReviewing={false}
      pageUrls={pageUrls}
      allSentences={allSentences}
      sentences={sentences}
      autoSaveTime={null}
    >
      <PreviewLayout />
    </ReviewStateProvider>
  );
};
