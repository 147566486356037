import React from "react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "@xstate/react";
import { Button, Spinner } from "@blueprintjs/core";
import { Intent, SpinnerSize } from "@blueprintjs/core";
import { v4 as uuidv4 } from "uuid";

import { UploadMachineState } from "../../stateMachines/upload.machine";
import { useUploadStateContext } from "../../utils/uploadState";
import { UploadWarningDialog } from "../dialogs/UploadWarningDialog";

import { colors } from "../../tokens";
import "./UploadButton.css";

const isFetchingSavedDocSelector = (state: UploadMachineState) => {
  return (
    state.matches("fetchingSavedDoc") || state.matches("fetchingSavedDocInfo")
  );
};
const isPostingSelector = (state: UploadMachineState) => {
  return (
    state.matches("fetchingUrl") ||
    state.matches("posting") ||
    state.matches("fetchingDoc")
  );
};
const isFinishingSelector = (state: UploadMachineState) => {
  return state.matches("finishing.previewing");
};
const isErroringSelector = (state: UploadMachineState) => {
  return state.matches("idle.errored");
};
const pdfIdSelector = (state: UploadMachineState) => {
  return state.context.uploadedDoc?.document_id;
};

export const UploadButton: React.FC = () => {
  const { uploadService } = useUploadStateContext();
  const { send } = uploadService;
  const isFetchingSavedDoc = useSelector(
    uploadService,
    isFetchingSavedDocSelector
  );
  const isPosting = useSelector(uploadService, isPostingSelector);
  const isFinishing = useSelector(uploadService, isFinishingSelector);
  const isErroring = useSelector(uploadService, isErroringSelector);
  const pdfId = useSelector(uploadService, pdfIdSelector);

  const [dragActive, setDragActive] = useState(false);
  const inputFile = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isFinishing) {
      navigate(`/preview/${pdfId}`);
    }
    if (isErroring) {
      navigate(`/error`);
    }
  }, [navigate, pdfId, isFinishing, isErroring]);

  const handleUploadFile = async (file: File) => {
    send({
      type: "SET_DOCUMENT",
      document_id: uuidv4(),
      document_name: file.name,
      document_size: file.size,
      document: file,
    });
  };

  const handleChange = (e: any) => {
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      handleUploadFile(e.target.files[0]);
      e.target.value = "";
    }
  };

  const handleClick = (e: any) => {
    e.target.value = "";
  };

  const handleDrag = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
    type: string;
  }) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleUploadFile(e.dataTransfer.files[0]);
      e.target.value = "";
    }
  };

  const onButtonClick = () => {
    if (inputFile.current !== null) {
      inputFile.current.click();
    }
  };

  return (
    <>
      <UploadWarningDialog />
      <div className="upload-wrapper">
        <form
          id="form-file-upload"
          onDragEnter={handleDrag}
          onSubmit={(e) => e.preventDefault()}
          encType={"multipart/form-data"}
        >
          <input
            type="file"
            id="input-file-upload"
            accept=".pdf"
            ref={inputFile}
            disabled={
              isFetchingSavedDoc || isPosting || isFinishing || isErroring
            }
            onChange={handleChange}
            onClick={handleClick}
          />
          <label
            className="upload-area"
            id="label-file-upload"
            htmlFor="input-file-upload"
          >
            <div id="content">
              {isPosting || isFinishing || isErroring ? (
                <div className="column">
                  <Spinner size={SpinnerSize.LARGE} />
                  <br />
                  <p
                    style={{
                      fontSize: "16px",
                      marginTop: "12px",
                      color: colors.primary4,
                    }}
                  >
                    Depending on the length of the contract, it may take a while
                    to process...
                  </p>
                  {/* <Button
                  onClick={() => send({ type: "CANCEL" })}
                  intent={Intent.DANGER}
                  icon="cross"
                >
                  Cancel
                </Button> */}
                </div>
              ) : (
                <p
                  style={{
                    fontSize: "24px",
                    marginBottom: "12px",
                    color: colors.primary4,
                  }}
                >
                  Select a PDF or drag PDF here
                </p>
              )}
            </div>
          </label>
          {dragActive && (
            <div
              id="drag-file-element"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            ></div>
          )}
        </form>
        <Button
          className="upload-button"
          intent={Intent.PRIMARY}
          large={true}
          icon="upload"
          text="Upload"
          disabled={
            isFetchingSavedDoc || isPosting || isFinishing || isErroring
          }
          onClick={onButtonClick}
        />
      </div>
    </>
  );
};
