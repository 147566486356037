import { ApolloClient } from "@apollo/client";
import { ActorRefFrom, StateFrom, actions, createMachine } from "xstate";

import { LOGIN_USERNAME_PASSWORD, FORGOT_PASSWORD } from "../graphql/graphql";

type AuthContext = {
  client: ApolloClient<object>;
  username: string | null;
  password: string | null;
  token: string | null;
  error: any | null;
};

type AuthEvents =
  | {
      type: "SET_CREDENTIALS";
      username: string;
      password: string;
    }
  | { type: "LOGIN" }
  | { type: "LOGOUT" }
  | { type: "INITIATE_PASSWORD_RESET" }
  | { type: "RESET_PASSWORD"; username: string }
  | { type: "REFRESH_TOKENS" }
  | { type: "NAV_AWAY" };

const getInitialState = (): Omit<AuthContext, "client"> => ({
  username: null,
  password: null,
  token: null,
  error: null,
});

export type AuthMachineActor = ActorRefFrom<typeof authMachine>;
export type AuthMachineState = StateFrom<typeof authMachine>;

export const authMachine = createMachine(
  {
    tsTypes: {} as import("./auth.machine.typegen").Typegen0,
    schema: {
      context: {} as AuthContext,
      events: {} as AuthEvents,
      services: {} as {
        loginUser: {
          data: {
            user_login_get_jwt: {
              user: {
                id: string;
                email: string;
              };
              access_jwt: string;
              refresh_jwt: string;
            };
          };
        };
        resetPassword: { data: { user_forgot_password: null } };
      },
    },
    // @ts-expect-error
    context: { ...getInitialState() },
    initial: "unauthenticated",
    states: {
      unauthenticated: {
        id: "unauthenticated",
        exit: "clearError",
        initial: "loginIdle",
        on: {
          RESET_PASSWORD: {
            actions: "assignUsername",
            target: "unauthenticated.resettingPassword",
          },
        },
        states: {
          loginIdle: {
            on: {
              SET_CREDENTIALS: {
                actions: "assignCredentials",
              },
              LOGIN: {
                target: "#authenticating",
              },
              INITIATE_PASSWORD_RESET: {
                target: "resetPasswordIdle",
              },
            },
          },
          resetPasswordIdle: {
            on: {
              RESET_PASSWORD: {
                actions: "assignUsername",
                target: "resettingPassword",
              },
            },
          },
          resettingPassword: {
            invoke: [
              {
                src: "resetPassword",
                onDone: {
                  target: "resetPasswordFinished",
                },
                onError: {
                  actions: "assignError",
                  target: "loginIdle",
                },
              },
            ],
          },
          resetPasswordFinished: {
            on: {
              NAV_AWAY: {
                target: "loginIdle",
              },
            },
          },
        },
      },
      authenticating: {
        id: "authenticating",
        invoke: [
          {
            src: "loginUser",
            onDone: [
              {
                cond: "isValidUser",
                actions: [
                  "assignUserId",
                  "assignRefreshToken",
                  "assignAccessToken",
                ],
                target: "authenticated",
              },
              {
                target: "unauthenticated",
              },
            ],
            onError: {
              actions: "assignError",
              target: "unauthenticated",
            },
          },
        ],
      },
      authenticated: {
        on: {
          LOGOUT: {
            actions: ["resetStorage", "resetContext"],
            target: "unauthenticated",
          },
        },
      },
    },
  },
  {
    services: {
      loginUser: async (ctx) => {
        const { username, password } = ctx;
        try {
          const results = await ctx.client.mutate({
            mutation: LOGIN_USERNAME_PASSWORD,
            variables: { email: username, password: password },
          });
          return results.data;
        } catch (error) {
          return Promise.reject(error);
        }
      },
      resetPassword: async (ctx) => {
        const { username } = ctx;
        try {
          const results = await ctx.client.mutate({
            mutation: FORGOT_PASSWORD,
            variables: { email: username },
          });
          return results.data;
        } catch (error) {
          return Promise.reject(error);
        }
      },
    },
    actions: {
      assignCredentials: actions.assign((_ctx, event) => {
        return { username: event.username, password: event.password };
      }),
      assignUsername: actions.assign((_ctx, event) => {
        return { username: event.username };
      }),
      assignUserId: actions.assign((_ctx, event) => {
        localStorage.setItem("id", event.data.user_login_get_jwt.user.id);
        return {};
      }),
      assignRefreshToken: actions.assign((_ctx, event) => {
        localStorage.setItem(
          "token",
          event.data.user_login_get_jwt.refresh_jwt
        );
        return {};
      }),
      assignAccessToken: actions.assign((_ctx, event) => {
        sessionStorage.setItem(
          "token",
          event.data.user_login_get_jwt.access_jwt
        );
        return {};
      }),
      assignError: actions.assign((_ctx, event) => {
        return { error: event };
      }),
      clearError: actions.assign((_ctx, _event) => {
        return { error: null };
      }),
      resetStorage: actions.assign((_ctx, _event) => {
        localStorage.clear();
        sessionStorage.clear();
        return {};
      }),
      resetContext: actions.assign((_ctx, _event) => {
        return {
          username: null,
          password: null,
          token: null,
          error: null,
        };
      }),
    },
    guards: {
      isValidUser: (_ctx, event) => {
        return Boolean(event.data.user_login_get_jwt.user) === true;
      },
    },
  }
);
