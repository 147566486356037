/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { HTMLProps } from "react";
import { useSelector } from "@xstate/react";
import {
  Button,
  ButtonGroup,
  Callout,
  Card,
  Divider,
  H4,
  Icon,
} from "@blueprintjs/core";
import { Position } from "@blueprintjs/core";
import { Classes, Tooltip2 } from "@blueprintjs/popover2";
import { SeverityScore, UserProblematic } from "@pham740/react-pdf-highlighter";
import { css } from "@emotion/react";

import { ReviewMachineState } from "../../stateMachines/review.machine";
import { useReviewStateContext } from "../../utils/reviewState";
import { Sentence } from "../../utils/types";
import { HighlightCard } from "./HighlightCard";

const ToolTipText = (
  <span>
    Phrases initially displayed in this evaluation
    <br />
    section were deemed problematic by the tool.
    <br />
    Please go through each phrase and review it.
  </span>
);

const isHighlightingSelector = (state: ReviewMachineState) => {
  return state.matches("reviewing.highlightingMode");
};
const sentencesSelector = (state: ReviewMachineState) => {
  return state.context.sentences;
};
const curPageNumberSelector = (state: ReviewMachineState) => {
  return state.context.curPageNumber;
};
const curSentenceNumberSelector = (state: ReviewMachineState) => {
  return state.context.curSentenceNumber;
};

interface Props extends HTMLProps<HTMLDivElement> {}

export const ProblematicPanel: React.FC<Props> = () => {
  const { reviewService } = useReviewStateContext();
  const { send } = reviewService;
  const isHighlighting = useSelector(reviewService, isHighlightingSelector);
  const sentences = useSelector(reviewService, sentencesSelector);
  const curPageNumber = useSelector(reviewService, curPageNumberSelector);
  const curSentenceNumber = useSelector(
    reviewService,
    curSentenceNumberSelector
  );

  const [isReviewing, setIsReviewing] = useState<boolean>(true);

  const setSeverityToUnreviewed = (sentence: Sentence) => {
    send({
      type: "SET_SEVERITY_SCORE",
      id: sentence.id,
      severity_score: SeverityScore.UNREVIEWED,
    });
  };
  const setSeverityToNo = (sentence: Sentence) => {
    send({
      type: "SET_SEVERITY_SCORE",
      id: sentence.id,
      severity_score: SeverityScore.NO,
    });
  };

  const renderEvalSection = (sentence: Sentence) => {
    return (
      <div css={divStyles}>
        <p>Highlighted phrase:</p>
        <H4>{sentence.content.text}</H4>
        <br />
        {sentence.info.user_problematic === UserProblematic.YES
          ? renderProblematicButtons(sentence)
          : renderInitialButtons(sentence)}
      </div>
    );
  };

  const renderInitialButtons = (sentence: Sentence) => {
    return (
      <>
        <p>Is this problematic?</p>
        <ButtonGroup fill>
          <Button
            icon="tick"
            text="Yes"
            active={sentence.info.user_problematic === UserProblematic.YES}
            onClick={() => {
              send({
                type: "SET_PROBLEMATIC",
                id: sentence.id,
                user_problematic: UserProblematic.YES,
              });
              setSeverityToUnreviewed(sentence);
              setIsReviewing(true);
            }}
          />
          <Button
            icon="cross"
            text="No"
            active={sentence.info.user_problematic === UserProblematic.NO}
            onClick={() => {
              send({
                type: "SET_PROBLEMATIC",
                id: sentence.id,
                user_problematic: UserProblematic.NO,
              });
              setSeverityToNo(sentence);
              setIsReviewing(false);
            }}
          />
          <Button
            icon="help"
            text="Unsure"
            active={sentence.info.user_problematic === UserProblematic.UNSURE}
            onClick={() => {
              send({
                type: "SET_PROBLEMATIC",
                id: sentence.id,
                user_problematic: UserProblematic.UNSURE,
              });
              setSeverityToUnreviewed(sentence);
              setIsReviewing(false);
            }}
          />
        </ButtonGroup>
        <ButtonGroup css={navButtonStyles}>
          <Button
            minimal={true}
            icon="arrow-left"
            text="PREVIOUS"
            onClick={() => send({ type: "PREVIOUS_SENTENCE" })}
          />
          <Button
            minimal={true}
            rightIcon="arrow-right"
            text="NEXT"
            onClick={() => send({ type: "NEXT_SENTENCE" })}
          />
        </ButtonGroup>
      </>
    );
  };

  const renderProblematicButtons = (sentence: Sentence) => {
    return (
      <>
        <p>How severe is the risk in the above statement?</p>
        <ButtonGroup fill>
          <Button
            text="High"
            active={sentence.info.severity_score === SeverityScore.HIGH}
            onClick={() => {
              send({
                type: "SET_SEVERITY_SCORE",
                id: sentence.id,
                severity_score: SeverityScore.HIGH,
              });
              setIsReviewing(false);
            }}
          />
          <Button
            text="Medium"
            active={sentence.info.severity_score === SeverityScore.MEDIUM}
            onClick={() => {
              send({
                type: "SET_SEVERITY_SCORE",
                id: sentence.id,
                severity_score: SeverityScore.MEDIUM,
              });
              setIsReviewing(false);
            }}
          />
          <Button
            text="Low"
            active={sentence.info.severity_score === SeverityScore.LOW}
            onClick={() => {
              send({
                type: "SET_SEVERITY_SCORE",
                id: sentence.id,
                severity_score: SeverityScore.LOW,
              });
              setIsReviewing(false);
            }}
          />
          <Button
            text="Unsure"
            active={sentence.info.severity_score === SeverityScore.UNSURE}
            onClick={() => {
              send({
                type: "SET_SEVERITY_SCORE",
                id: sentence.id,
                severity_score: SeverityScore.UNSURE,
              });
              setIsReviewing(false);
            }}
          />
        </ButtonGroup>
        <ButtonGroup css={navButtonStyles}>
          <Button
            minimal={true}
            icon="arrow-left"
            text="PREVIOUS"
            onClick={() => send({ type: "PREVIOUS_SENTENCE" })}
          />
          <Button
            minimal={true}
            rightIcon="arrow-right"
            text="NEXT"
            onClick={() => send({ type: "NEXT_SENTENCE" })}
          />
        </ButtonGroup>
      </>
    );
  };
  const renderDoneSection = () => {
    return (
      <div css={doneStyles}>
        <H4>This section has been completed.</H4>
        <ButtonGroup css={doneButtonStyles}>
          <Button
            large={true}
            minimal={true}
            icon="redo"
            text="REDO"
            onClick={() => {
              send({ type: "REDO_HIGHLIGHT" });
              setIsReviewing(true);
            }}
          />
          <Button
            large={true}
            minimal={true}
            rightIcon="arrow-right"
            text="NEXT"
            onClick={() => {
              send({ type: "NEXT_SENTENCE" });
              setIsReviewing(true);
            }}
          />
        </ButtonGroup>
      </div>
    );
  };

  const renderRegularCard = () => {
    return isReviewing ? (
      sentences[curPageNumber].length === 0 ? (
        <p css={noSentenceStyles}>No problematic sentences on this page</p>
      ) : (
        renderEvalSection(sentences[curPageNumber][curSentenceNumber])
      )
    ) : (
      renderDoneSection()
    );
  };
  return (
    <Card>
      <H4>Problematic Phrases Detected</H4>
      <Divider />
      <p>
        By reviewing the section below, you will be improving the quality and
        detection of the application.
        <br />
        Go through each phrase and select the term that fits best with the
        phrase.
      </p>
      <br />
      <Card css={cardStyles}>
        <Callout css={rowStyles} intent="primary" icon={null}>
          <H4>Evaluation section </H4>
          <Tooltip2
            className={Classes.TOOLTIP2_INDICATOR}
            content={ToolTipText}
            position={Position.RIGHT}
          >
            <Icon icon="help" />
          </Tooltip2>
        </Callout>
        {isHighlighting ? <HighlightCard /> : renderRegularCard()}
      </Card>
    </Card>
  );
};

const noSentenceStyles = css`
  font-size 16px;
  margin-top: auto;
  margin-bottom: auto;
`;

const cardStyles = css`
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const rowStyles = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const divStyles = css`
  width: 100%;
  padding: 24px;
`;

const doneStyles = css`
  padding: 24px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
`;

const navButtonStyles = css`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`;

const doneButtonStyles = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
