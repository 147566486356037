import React from "react";
import { useApolloClient } from "@apollo/client";
import { useInterpret } from "@xstate/react";
import { createContext, HTMLProps, useContext } from "react";

import {
  uploadMachine,
  UploadMachineActor,
} from "../stateMachines/upload.machine";

interface UploadStateProps extends HTMLProps<HTMLDivElement> {}

export const UploadStateContext = createContext<{
  uploadService: UploadMachineActor;
  // @ts-expect-error
}>({});

export const UploadStateProvider: React.FC<UploadStateProps> = (props) => {
  const client = useApolloClient();
  const uploadService = useInterpret(uploadMachine, {
    context: { client },
  });

  return (
    <UploadStateContext.Provider value={{ uploadService }}>
      {!!uploadService && props.children}
    </UploadStateContext.Provider>
  );
};

export const useUploadStateContext = () => {
  const context = useContext(UploadStateContext);

  if (!context) {
    throw new Error(
      "useUploadStateContext must be used within the UploadStateContext.Provider"
    );
  }

  return context;
};
