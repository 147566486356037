import React from "react";
import { useApolloClient } from "@apollo/client";
import { useInterpret } from "@xstate/react";
import { createContext, HTMLProps, useContext } from "react";

import {
  reviewMachine,
  ReviewMachineActor,
} from "../stateMachines/review.machine";
import { Page } from "./types";

interface ReviewStateProps extends HTMLProps<HTMLDivElement> {
  docId: string | undefined;
  isReviewing: boolean;
  pageUrls: Page[];
  allSentences: any;
  sentences: any;
  autoSaveTime: Date | null;
}

export const ReviewStateContext = createContext<{
  reviewService: ReviewMachineActor;
  // @ts-expect-error
}>({});

export const ReviewStateProvider: React.FC<ReviewStateProps> = ({
  docId,
  isReviewing,
  pageUrls,
  allSentences,
  sentences,
  autoSaveTime,
  ...props
}) => {
  const client = useApolloClient();
  const reviewService = useInterpret(reviewMachine, {
    context: {
      client,
      docId,
      isReviewing,
      pageUrls,
      allSentences,
      sentences,
      autoSaveTime,
    },
  });

  return (
    <ReviewStateContext.Provider value={{ reviewService }}>
      {!!reviewService && props.children}
    </ReviewStateContext.Provider>
  );
};

export const useReviewStateContext = () => {
  const context = useContext(ReviewStateContext);

  if (!context) {
    throw new Error(
      "useReviewStateContext must be used within the ReviewStateContext.Provider"
    );
  }

  return context;
};
