import { ErrorUploading } from "../components/error/ErrorUploading";

interface ErrorViewProps {
  css?: any;
}

export const ErrorView: React.FC<ErrorViewProps> = () => {
  return (
    <div className="centered">
      <ErrorUploading />
    </div>
  );
};
