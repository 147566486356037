/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "@xstate/react";
import { AnchorButton } from "@blueprintjs/core";
import { Intent } from "@blueprintjs/core";
import { css } from "@emotion/react";

import { ReviewMachineState } from "../../stateMachines/review.machine";
import { useReviewStateContext } from "../../utils/reviewState";
import { useUploadStateContext } from "../../utils/uploadState";

import { DocumentTabs } from "../document/DocumentTabs";
import { PdfViewer } from "../document/PdfViewer";
import { ProblematicPanel } from "../document/ProblematicPanel";
import { ExportDialog } from "../dialogs/ExportDialog";

import { colors } from "../../tokens";

const autoSaveDateSelector = (state: ReviewMachineState) => {
  return state.context.autoSaveTime;
};
const exportDocSelector = (state: ReviewMachineState) => {
  return state.context.exportDoc;
};

interface ReviewLayoutProps {
  css?: any;
}

export const ReviewLayout: React.FC<ReviewLayoutProps> = () => {
  const { uploadService } = useUploadStateContext();
  const { send: uploadSend } = uploadService;

  const { reviewService } = useReviewStateContext();
  const { send: reviewSend } = reviewService;
  const autoSaveDate = useSelector(reviewService, autoSaveDateSelector);
  const exportDocUrl = useSelector(reviewService, exportDocSelector);

  const autoSaveTime = autoSaveDate?.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (exportDocUrl !== null) {
      //Open the URL on new Window
      navigate(`/`);
      window.open(exportDocUrl, "_blank", "noreferrer");
    }
  }, [navigate, exportDocUrl]);

  return (
    <>
      <ExportDialog />
      <div css={ReviewStyles}>
        <div className="column">
          <div css={ButtonStyles}>
            <AnchorButton
              text="Exit"
              onClick={() => {
                uploadSend({ type: "CANCEL" });
                reviewSend({ type: "CANCEL" });
                navigate(`/`);
              }}
            />
            <AnchorButton
              intent={Intent.PRIMARY}
              text="Finish"
              onClick={() => {
                reviewSend({ type: "FINISH" });
              }}
            />
          </div>
          <div css={RowStyles}>
            <DocumentTabs
              pdfTab={<PdfViewer isHighlightable={false} type="pdf" />}
              textTab={<PdfViewer isHighlightable={true} type="text" />}
            />
            <ProblematicPanel />
          </div>
          <div css={TimeStampStyles}>
            This document was auto-saved at {autoSaveTime}.
          </div>
        </div>
      </div>
    </>
  );
};

const ReviewStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
`;

const RowStyles = css`
  display: grid;
  grid-template-columns: 47vw 47vw;
  grid-gap: 20px;
  height: 100%;
  width: 100%;
`;

const ButtonStyles = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 12px;
`;

const TimeStampStyles = css`
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;

  padding: 16px;
  width: fit-content;
  color: ${colors.primary4};

  border-radius: 1rem;
  border: solid;
  border-width: 1px;
  border-color: ${colors.primary5};
  background-color: ${colors.primary6};
`;
