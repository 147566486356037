/** @jsxImportSource @emotion/react */
import { createRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "@xstate/react";
import { Classes, Intent, SpinnerSize } from "@blueprintjs/core";
import { Button, H6, Spinner } from "@blueprintjs/core";
import { css } from "@emotion/react";

import { AuthMachineState } from "../stateMachines/auth.machine";
import { useAppStateContext } from "../utils/appState";
import { Disclaimer } from "../components/Disclaimer";

const isAuthenticatingSelector = (state: AuthMachineState) => {
  return state.matches("authenticating");
};

interface LoginViewProps {
  css?: any;
}

export const LoginView: React.FC<LoginViewProps> = () => {
  const { authService } = useAppStateContext();
  const { send } = authService;
  const isAuthenticating = useSelector(authService, isAuthenticatingSelector);

  const usernameRef = createRef<HTMLInputElement>();
  const passwordRef = createRef<HTMLInputElement>();

  const [showDisclaimer, setShowDisclaimer] = useState(true);

  const navigate = useNavigate();

  const handleSubmit = (event: any) => {
    event.preventDefault();
    send({
      type: "SET_CREDENTIALS",
      username: String(usernameRef.current?.value),
      password: String(passwordRef.current?.value),
    });
    send({ type: "LOGIN" });
  };

  return (
    <>
      <Disclaimer
        showDisclaimer={showDisclaimer}
        setShowDisclaimer={setShowDisclaimer}
      />

      <div css={DisclaimerButtonStyles}>
        <Button
          intent={Intent.PRIMARY}
          icon="chevron-down"
          onClick={() => setShowDisclaimer(true)}
        />
      </div>

      <div css={DivStyles} className="centered">
        <form className={Classes.FORM_GROUP} onSubmit={handleSubmit}>
          <label css={LabelStyles} className={Classes.LABEL}>
            <div css={HeaderStyles}>
              <img
                src={`${process.env.PUBLIC_URL}/RiskReviewLogo.svg`}
                alt="RiskReview Standard Logo"
              />
            </div>
            <H6>Email</H6>
            <input
              disabled={isAuthenticating}
              ref={usernameRef}
              id="email"
              className={Classes.INPUT}
              type="email"
              name="email"
              autoComplete="username"
              required
              autoFocus
            />
          </label>
          <label css={LabelStyles} className={Classes.LABEL}>
            <H6>Password</H6>
            <input
              disabled={isAuthenticating}
              ref={passwordRef}
              id="password"
              className={Classes.INPUT}
              type="password"
              name="password"
              autoComplete="current-password"
              required
              autoFocus
            />
          </label>
          <Button
            disabled={isAuthenticating}
            type="submit"
            className={Classes.FILL}
            intent={Intent.PRIMARY}
          >
            {isAuthenticating ? <Spinner size={SpinnerSize.SMALL} /> : "Submit"}
          </Button>
        </form>
        <Button
          disabled={isAuthenticating}
          minimal
          intent={Intent.PRIMARY}
          text="Forgot password? Click here"
          onClick={() => {
            send({ type: "INITIATE_PASSWORD_RESET" });
            navigate("/forgot-password");
          }}
        />
      </div>
    </>
  );
};

const DisclaimerButtonStyles = css`
  position: fixed;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
`;

const DivStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const HeaderStyles = css`
  display: flex;
  flex-direction: row;
  justify-content: center;

  img {
    max-width: 200px;
  }
`;

const LabelStyles = css`
  h6 {
    margin: 0px;
    margin-top: 4px;
    margin-bottom: -2px;
  }

  input {
    width: 100%;
  }
`;
