import { createRoot } from "react-dom/client";
import { IntlProvider } from "react-intl";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  from,
} from "@apollo/client";
import { Global } from "@emotion/react";

import errorLink from "./graphql/ErrorLink";
import authLink from "./graphql/AuthLink";
import uploadLink from "./graphql/UploadLink";
import { App } from "./App";
import { cssClasses, cssThemes } from "./tokens";
import { AppStateProvider } from "./utils/appState";
import { UploadStateProvider } from "./utils/uploadState";

import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, authLink, uploadLink]),
});

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
root.render(
  <>
    <Global styles={[cssClasses, cssThemes]} />
    <IntlProvider locale="en">
      <ApolloProvider client={apolloClient}>
        <AppStateProvider>
          <UploadStateProvider>
            <App />
          </UploadStateProvider>
        </AppStateProvider>
      </ApolloProvider>
    </IntlProvider>
  </>
);
