import { gql } from "@apollo/client";

export const REFRESH_TOKENS = gql`
  mutation ($user_id: UUID!) {
    user_refresh_jwt(user_id: $user_id) {
      access_jwt
      refresh_jwt
    }
  }
`;

export const LOGIN_USERNAME_PASSWORD = gql`
  query ($email: String!, $password: String!) {
    user_login_get_jwt(email: $email, password: $password) {
      user {
        id
        email
      }
      access_jwt
      refresh_jwt
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ($email: String!) {
    user_forgot_password(email: $email)
  }
`;

export const GET_SAVED_DOCUMENTS = gql`
  query ($id: UUID!) {
    user(id: $id) {
      active_document {
        id
        filename
        creation_time
        last_edit_time
      }
    }
  }
`;

export const UPLOAD_DOC = gql`
  mutation (
    $document_id: String!
    $document_name: String!
    $document: Upload!
  ) {
    uploadDocument(
      document_id: $document_id
      document_name: $document_name
      document: $document
    ) {
      id
      filename
      s3_bucket
      s3_key
      pages {
        s3_raw_url
        s3_ocr_url
        id
        sentences {
          id
          text
          problematic
          rectangles {
            id
            x0
            x1
            y0
            y1
          }
        }
      }
    }
  }
`;

export const GET_UPLOAD_URL = gql`
  mutation ($document_id: UUID!, $document_name: String!) {
    createS3PostUrl(document_id: $document_id, document_name: $document_name)
  }
`;

export const GET_DOC = gql`
  query ($id: String!) {
    document(id: $id) {
      id
      filename
      s3_bucket
      s3_key
      pages {
        s3_raw_url
        s3_ocr_url
        id
        sentences {
          id
          text
          problematic
          rectangles {
            id
            x0
            x1
            y0
            y1
          }
          feedback {
            id
            sentence_id
            page_id
            document_id
            user_defined
            text
            problematic
            severity_score
          }
        }
      }
    }
  }
`;

export const CREATE_DOC = gql`
  mutation ($doc_id: UUID!) {
    createDocumentFromID(document_id: $doc_id) {
      id
      filename
      s3_bucket
      s3_key
      pages {
        s3_raw_url
        s3_ocr_url
        id
        sentences {
          id
          text
          problematic
          rectangles {
            id
            x0
            x1
            y0
            y1
          }
        }
      }
    }
  }
`;

export const CLEAR_SAVED_DOC = gql`
  mutation ($id: UUID!) {
    clearActiveDocumentForUser(user_id: $id)
  }
`;

export const UPLOAD_FEEDBACK = gql`
  mutation ($data: FeedbackCreateInput!) {
    feedback_create(data: $data) {
      id
      problematic
      severity_score
      sentence {
        id
        problematic
        user_defined
        text
        rectangles {
          x0
          x1
          y0
          y1
        }
      }
      user_defined
    }
  }
`;

export const UPLOAD_SENTENCE = gql`
  mutation ($data: SentenceCreateInput!) {
    sentence_create(data: $data) {
      id
      problematic
      severity_score
      sentence {
        id
        problematic
        user_defined
        text
        rectangles {
          x0
          x1
          y0
          y1
        }
      }
      user_defined
    }
  }
`;

export const GET_EXPORT_DOC = gql`
  mutation ($id: UUID!) {
    exportDocument(document_id: $id)
  }
`;
