/** @jsxImportSource @emotion/react */
import { createRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "@xstate/react";
import { Classes, Intent, SpinnerSize } from "@blueprintjs/core";
import { Button, H3, H6, Icon, Spinner } from "@blueprintjs/core";
import { css } from "@emotion/react";

import { AuthMachineState } from "../stateMachines/auth.machine";
import { useAppStateContext } from "../utils/appState";

import { colors } from "../tokens";

const isResettingPasswordSelector = (state: AuthMachineState) => {
  return state.matches("unauthenticated.resettingPassword");
};
const isResetPasswordFinishedSelector = (state: AuthMachineState) => {
  return state.matches("unauthenticated.resetPasswordFinished");
};

export const ForgotPasswordView: React.FC = () => {
  const { authService } = useAppStateContext();
  const { send } = authService;
  const isResettingPassword = useSelector(
    authService,
    isResettingPasswordSelector
  );
  const isResetPasswordFinished = useSelector(
    authService,
    isResetPasswordFinishedSelector
  );

  const navigate = useNavigate();
  const usernameRef = createRef<HTMLInputElement>();

  const handleSubmit = (event: any) => {
    event.preventDefault();
    send({
      type: "RESET_PASSWORD",
      username: String(usernameRef.current?.value),
    });
  };

  return isResetPasswordFinished ? (
    <div css={divStyles} className="centered">
      <Icon icon="endorsed" intent={Intent.PRIMARY} size={100} />
      <br />
      <H3>Your request has been submitted.</H3>
      <p>
        If we find an account matching the information that was provided, we
        will send you an email with your new password.
        <br />
        <br />
        <strong>Note: </strong>
        If you can't find the email in your inbox, you may need to check your
        junk mail folder.
      </p>
      <Button
        minimal
        intent={Intent.PRIMARY}
        icon="arrow-left"
        text="Back to login page"
        onClick={() => {
          send({ type: "NAV_AWAY" });
          navigate("/");
        }}
      />
    </div>
  ) : (
    <div css={divStyles} className="centered">
      <form className={Classes.FORM_GROUP} onSubmit={handleSubmit}>
        <label css={LabelStyles} className={Classes.LABEL}>
          <div css={HeaderStyles}>
            <H3>Forgot Password</H3>
          </div>
          <H6>Email</H6>
          <input
            disabled={isResettingPassword}
            ref={usernameRef}
            id="email"
            className={Classes.INPUT}
            type="email"
            name="email"
            autoComplete="username"
            required
            autoFocus
          />
        </label>
        <Button
          disabled={isResettingPassword}
          className={Classes.FILL}
          intent={Intent.PRIMARY}
          type="submit"
        >
          {isResettingPassword ? (
            <Spinner size={SpinnerSize.SMALL} />
          ) : (
            "Submit"
          )}
        </Button>
      </form>
      <Button
        disabled={isResettingPassword}
        minimal
        intent={Intent.PRIMARY}
        icon="arrow-left"
        text="Back to login page"
        onClick={() => {
          send({ type: "NAV_AWAY" });
          navigate("/");
        }}
      />
    </div>
  );
};

const divStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    max-width: 400px;
  }

  svg {
    color: ${colors.primary2};
  }
`;

const HeaderStyles = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const LabelStyles = css`
  h6 {
    margin: 0px;
    margin-top: 4px;
    margin-bottom: -2px;
  }
`;
