import { ApolloClient } from "@apollo/client";
import { ActorRefFrom, StateFrom, actions, createMachine } from "xstate";

import {
  getExportPdfResponse,
  Page,
  postFeedbackResponse,
  Rectangle,
  Selection,
  Sentence,
  SentenceCache,
  feedbackVariables,
} from "../utils/types";
import {
  ScaledPosition,
  SeverityScore,
  UserProblematic,
} from "@pham740/react-pdf-highlighter";
import {
  CLEAR_SAVED_DOC,
  UPLOAD_FEEDBACK,
  GET_EXPORT_DOC,
} from "../graphql/graphql";

type ReviewContext = {
  client: ApolloClient<object>;
  isReviewing: boolean | null;
  docId: string | undefined;
  curPageNumber: number;
  pageUrls: Page[];
  curSentenceNumber: number;
  allSentences: any;
  sentences: any;
  sentenceCache: SentenceCache | null;
  selection: Selection | null;
  exportDoc: string | null;
  autoSaveTime: Date | null;
  error: any | null;
};

type NavigationEvents =
  | { type: "SET_PAGE"; page_num: number }
  | { type: "PREVIOUS_PAGE" }
  | { type: "NEXT_PAGE" }
  | { type: "PREVIOUS_SENTENCE" }
  | { type: "NEXT_SENTENCE" }
  | { type: "NAV_AWAY" }
  | { type: "CANCEL" }
  | { type: "CONTINUE" }
  | { type: "FINISH" }
  | { type: "CLOSE" }
  | { type: "EXPORT" };

type ReviewEvents =
  | { type: "TOGGLE" }
  | { type: "GO_TO_HIGHLIGHT"; id: string }
  | {
      type: "SET_SELECTION_TEXT";
      text: string | undefined;
      position: ScaledPosition;
    }
  | {
      type: "SET_SELECTION_SCORE";
      severity_score: SeverityScore;
      position: ScaledPosition;
    }
  | {
      type: "SET_PROBLEMATIC";
      id: string;
      user_problematic: UserProblematic;
    }
  | {
      type: "SET_SEVERITY_SCORE";
      id: string;
      severity_score: SeverityScore;
    }
  | { type: "CANCEL_HIGHLIGHT"; id?: string }
  | { type: "REDO_HIGHLIGHT" }
  | { type: "DELETE_HIGHLIGHT"; id: string }
  | { type: "ADD_HIGHLIGHT"; position: ScaledPosition }
  | { type: "SAVE_HIGHLIGHT" }
  | {
      type: "UPDATE_HIGHLIGHT";
      id: string;
      position: Object;
      content: Object;
    };

const getInitialState = (): Omit<
  ReviewContext,
  | "client"
  | "docId"
  | "isReviewing"
  | "pageUrls"
  | "allSentences"
  | "sentences"
  | "autoSaveTime"
> => ({
  curPageNumber: 0,
  curSentenceNumber: 0,
  sentenceCache: null,
  selection: null,
  exportDoc: null,
  error: null,
});

export type ReviewMachineActor = ActorRefFrom<typeof reviewMachine>;
export type ReviewMachineState = StateFrom<typeof reviewMachine>;

export const reviewMachine = createMachine(
  {
    tsTypes: {} as import("./review.machine.typegen").Typegen0,
    schema: {
      context: {} as ReviewContext,
      events: {} as NavigationEvents | ReviewEvents,
      services: {} as {
        postFeedback: { data: postFeedbackResponse };
        getExportPdf: { data: getExportPdfResponse };
      },
    },
    // @ts-expect-error
    context: { ...getInitialState() },
    initial: "decideIfReviewing",
    states: {
      idle: {
        initial: "noError",
        states: {
          noError: {
            exit: ["clearError", "resetContext"],
          },
          errored: {
            on: {
              NAV_AWAY: {
                actions: ["clearError", "resetContext"],
                target: "noError",
              },
            },
          },
        },
      },
      decideIfReviewing: {
        always: [
          {
            cond: (ctx, _event) => {
              return ctx.isReviewing === true;
            },
            target: "reviewing",
          },
          {
            target: "previewing",
          },
        ],
      },
      clearingSavedDoc: {
        invoke: [
          {
            src: "clearingSavedDoc",
            onDone: {
              cond: "hasResponse",
              target: "idle.noError",
            },
            onError: {
              actions: "assignError",
            },
          },
        ],
      },
      previewing: {
        exit: [
          "findFirstReviewablePage",
          "resetCurSentenceNumber",
          "assignActiveSentence",
        ],
        on: {
          SET_PAGE: {
            actions: "assignCurPageNumber",
          },
          PREVIOUS_PAGE: {
            actions: ["assignPreviousPage", "resetCurSentenceNumber"],
          },
          NEXT_PAGE: {
            actions: ["assignNextPage", "resetCurSentenceNumber"],
          },
          CANCEL: {
            target: "idle.noError",
          },
          CONTINUE: {
            target: "reviewing.regularMode",
          },
          NAV_AWAY: {
            target: "idle.noError",
          },
        },
      },
      reviewing: {
        entry: "assignActiveSentence",
        on: {
          REDO_HIGHLIGHT: {
            actions: [
              "redoHighlight",
              "assignActiveSentence",
              "resetSentenceCache",
            ],
          },
          GO_TO_HIGHLIGHT: {
            actions: ["assignCurSentenceNumber", "assignActiveSentence"],
          },
          SET_PAGE: {
            actions: "assignCurPageNumber",
          },
          CANCEL: {
            target: "idle.noError",
          },
          FINISH: {
            target: "finishing",
          },
          NAV_AWAY: {
            target: "idle.noError",
          },
        },
        initial: "regularMode",
        states: {
          history: {
            type: "history",
            history: "shallow",
          },
          regularMode: {
            on: {
              TOGGLE: {
                actions: ["setAllActive", "resetSentenceCache"],
                target: "highlightingMode",
              },
              PREVIOUS_PAGE: {
                actions: [
                  "assignPreviousPage",
                  "resetSentenceCache",
                  "resetCurSentenceNumber",
                  "assignActiveSentence",
                ],
              },
              NEXT_PAGE: {
                actions: [
                  "assignNextPage",
                  "resetSentenceCache",
                  "resetCurSentenceNumber",
                  "assignActiveSentence",
                ],
              },
              PREVIOUS_SENTENCE: {
                actions: [
                  "assignPrevSentence",
                  "assignActiveSentence",
                  "resetSentenceCache",
                ],
              },
              NEXT_SENTENCE: {
                actions: [
                  "assignNextSentence",
                  "assignActiveSentence",
                  "resetSentenceCache",
                ],
              },
              DELETE_HIGHLIGHT: {
                actions: ["deleteHighlight", "assignActiveSentence"],
                target: "#postingFeedback",
              },
              SET_PROBLEMATIC: {
                actions: "assignProblematic",
                target: "#postingFeedback",
              },
              SET_SEVERITY_SCORE: {
                actions: "assignSeverityScore",
                target: "#postingFeedback",
              },
            },
          },
          highlightingMode: {
            id: "highlightingMode",
            on: {
              TOGGLE: {
                actions: [
                  "resetSelection",
                  "resetCurSentenceNumber",
                  "assignActiveSentence",
                ],
                target: "regularMode",
              },
              PREVIOUS_PAGE: {
                actions: [
                  "resetSelection",
                  "assignPreviousPage",
                  "resetCurSentenceNumber",
                  "setAllActive",
                ],
              },
              NEXT_PAGE: {
                actions: [
                  "resetSelection",
                  "assignNextPage",
                  "resetCurSentenceNumber",
                  "setAllActive",
                ],
              },
              DELETE_HIGHLIGHT: {
                actions: "deleteHighlight",
                target: "#postingFeedback",
              },
              SET_SELECTION_TEXT: {
                actions: [
                  "resetSelection",
                  "assignSelectionText",
                  "addHighlight",
                ],
                target: "#postingSelectionText",
              },
              SET_SELECTION_SCORE: {
                actions: ["assignSelectionScore", "addHighlight"],
                target: "#postingFeedback",
              },
              UPDATE_HIGHLIGHT: {
                actions: "updateHighlight",
                target: "#postingFeedback",
              },
              SAVE_HIGHLIGHT: {
                actions: "resetSelection",
                target: "#postingFeedback",
              },
              CANCEL_HIGHLIGHT: {
                actions: ["deleteHighlight", "resetSelection"],
                target: "#postingFeedback",
              },
            },
          },
        },
      },
      postingSelectionText: {
        id: "postingSelectionText",
        invoke: [
          {
            src: "postFeedback",
            onDone: {
              actions: ["assignAutoSaveTime", "assignHighlightSentenceId"],
              target: "reviewing.highlightingMode",
            },
          },
        ],
      },
      postingFeedback: {
        id: "postingFeedback",
        invoke: [
          {
            src: "postFeedback",
            onDone: {
              actions: "assignAutoSaveTime",
              target: "reviewing.regularMode",
            },
          },
        ],
      },
      finishing: {
        on: {
          CLOSE: {
            target: "reviewing.regularMode",
          },
          CANCEL: {
            target: "postingUnreviewedHighlightsOnCancel",
          },
          EXPORT: {
            target: "postingUnreviewedwHighlightsOnExport",
          },
        },
      },
      postingUnreviewedHighlightsOnCancel: {
        invoke: [
          {
            src: "postUnreviewedHighlights",
            onDone: {
              target: "idle.noError",
            },
            onError: {
              actions: "assignError",
              target: "idle.errored",
            },
          },
        ],
      },
      postingUnreviewedwHighlightsOnExport: {
        invoke: [
          {
            src: "postUnreviewedHighlights",
            onDone: {
              target: "fetchingExport",
            },
            onError: {
              actions: "assignError",
              target: "idle.errored",
            },
          },
        ],
      },
      fetchingExport: {
        invoke: [
          {
            src: "getExportPdf",
            onDone: {
              cond: "hasResponse",
              actions: "assignExportDoc",
              target: "idle.noError",
            },
            onError: {
              actions: "assignError",
              target: "idle.errored",
            },
          },
        ],
      },
    },
  },
  {
    services: {
      clearingSavedDoc: async (ctx, _event) => {
        try {
          const results = await ctx.client.mutate({
            mutation: CLEAR_SAVED_DOC,
            variables: {
              id: localStorage.getItem("id"),
            },
          });
          return results.data;
        } catch (error) {
          return Promise.reject(error);
        }
      },
      postFeedback: async (ctx, _event) => {
        const {
          docId,
          sentences,
          curPageNumber,
          curSentenceNumber,
          sentenceCache,
        } = ctx;
        const sentence = sentences[curPageNumber][curSentenceNumber];
        try {
          let variables;
          if (
            sentences[curPageNumber].length === 0 &&
            sentenceCache?.sentence.info.user_problematic === UserProblematic.NO
          ) {
            variables = setFeedbackVariables(docId, sentenceCache?.sentence);
          } else if (isSelectionText(sentence)) {
            variables = setSelectionTextVariables(docId, sentence);
          } else {
            variables = setFeedbackVariables(docId, sentence);
          }
          const results = await ctx.client.mutate({
            mutation: UPLOAD_FEEDBACK,
            variables: variables,
          });
          return results.data;
        } catch (error) {
          return Promise.reject(error);
        }
      },
      postUnreviewedHighlights: async (ctx, _event) => {
        const { docId, sentences } = ctx;
        sentences.forEach((page: any, _index: number) => {
          page.forEach(async (sentence: Sentence) => {
            try {
              if (
                sentence.info.user_problematic === UserProblematic.UNREVIEWED
              ) {
                const variables = setFeedbackVariables(docId, sentence);
                const results = await ctx.client.mutate({
                  mutation: UPLOAD_FEEDBACK,
                  variables: variables,
                });
                return results.data;
              }
            } catch (error) {
              return Promise.reject(error);
            }
          });
        });
      },
      getExportPdf: async (ctx, _event) => {
        const { docId } = ctx;
        try {
          const results = await ctx.client.mutate({
            mutation: GET_EXPORT_DOC,
            variables: {
              id: docId,
            },
          });
          return results.data;
        } catch (error) {
          return Promise.reject(error);
        }
      },
    },
    actions: {
      findFirstReviewablePage: actions.assign((ctx, _event) => {
        const { sentences } = ctx;
        for (let i = 0; i < sentences.length; i++) {
          if (sentences[i].length) {
            return { curPageNumber: i };
          }
        }
        return { curPageNumber: 0 };
      }),
      assignAutoSaveTime: actions.assign((_ctx, _event) => {
        return { autoSaveTime: new Date() };
      }),
      assignHighlightSentenceId: actions.assign((ctx, event) => {
        const { allSentences, sentences, curPageNumber } = ctx;

        const updatedAllSentences = [...allSentences];
        updatedAllSentences[curPageNumber][
          updatedAllSentences[curPageNumber].length - 1
        ]["id"] = event.data.feedback_create.sentence?.id;
        const updatedSentences = [...sentences];
        updatedSentences[curPageNumber][
          updatedSentences[curPageNumber].length - 1
        ]["id"] = event.data.feedback_create.sentence?.id;
        return {
          allSentences: updatedAllSentences,
          sentences: updatedSentences,
        };
      }),
      assignExportDoc: actions.assign((_ctx, event) => {
        return { exportDoc: event.data.exportDocument };
      }),
      assignActiveSentence: actions.assign((ctx, event) => {
        const { sentences, curPageNumber, curSentenceNumber, sentenceCache } =
          ctx;
        const updatedSentences = [...sentences];
        const updatedPageData = [...updatedSentences[curPageNumber]].map(
          (sentence: Sentence) => {
            if (
              sentence.id === sentences[curPageNumber][curSentenceNumber].id &&
              !(
                event.type.includes("postingFeedback") &&
                sentenceCache?.sentence.info.user_problematic ===
                  UserProblematic.NO
              )
            ) {
              return { ...sentence, isActive: true };
            } else {
              return { ...sentence, isActive: false };
            }
          }
        );
        updatedSentences[curPageNumber] = updatedPageData;
        return { sentences: updatedSentences };
      }),
      assignPrevSentence: actions.assign((ctx, _event) => {
        const { curSentenceNumber, sentences, curPageNumber } = ctx;
        const curPageSentences = sentences[curPageNumber].length;
        let newPageNumber = curPageNumber;
        let newSentenceNumber = curSentenceNumber - 1;

        if (isInRange(newSentenceNumber, curPageSentences)) {
          newSentenceNumber = findInRangeValue(
            curSentenceNumber,
            newSentenceNumber,
            curPageSentences
          );
        } else {
          for (
            let i = curPageNumber - 1;
            i % sentences.length < sentences.length;
            i--
          ) {
            if (Math.sign(i) === 1 || Math.sign(i) === 0) {
              newPageNumber = i % sentences.length;
            } else if (sentences.length === 1) {
              newPageNumber = 0;
            } else {
              newPageNumber = (i % sentences.length) + sentences.length;
            }

            if (sentences[newPageNumber].length !== 0) {
              newSentenceNumber = sentences[newPageNumber].length - 1;
              break;
            }
          }
        }

        return {
          curPageNumber: newPageNumber,
          curSentenceNumber: newSentenceNumber,
        };
      }),
      assignNextSentence: actions.assign((ctx, _event) => {
        const { curSentenceNumber, sentences, curPageNumber, sentenceCache } =
          ctx;
        const pageSentenceCount = sentences[curPageNumber].length;
        let newPageNumber = curPageNumber;
        let newSentenceNumber = curSentenceNumber + 1;

        if (
          sentenceCache?.sentence.info.user_problematic ===
            UserProblematic.NO &&
          curSentenceNumber !== pageSentenceCount - 1
        ) {
          newSentenceNumber = newSentenceNumber - 1;
        }

        if (!isInRange(newSentenceNumber, pageSentenceCount)) {
          for (
            let i = curPageNumber + 1;
            i % sentences.length < sentences.length;
            i++
          ) {
            if (sentences[i % sentences.length].length !== 0) {
              newPageNumber = i % sentences.length;
              newSentenceNumber = 0;
              break;
            }
          }
        }

        return {
          curPageNumber: newPageNumber,
          curSentenceNumber: newSentenceNumber,
        };
      }),
      assignCurPageNumber: actions.assign((ctx, event) => {
        const { curPageNumber, pageUrls } = ctx;
        const totalPages = pageUrls.length;
        const newPageNumber = event.page_num;
        const pageNumber = findInRangeValue(
          curPageNumber,
          newPageNumber,
          totalPages
        );
        return { curPageNumber: pageNumber };
      }),
      assignPreviousPage: actions.assign((ctx, _event) => {
        const { curPageNumber, pageUrls } = ctx;
        const totalPages = pageUrls.length;
        const newPageNumber = curPageNumber - 1;
        const pageNumber = findInRangeValue(
          curPageNumber,
          newPageNumber,
          totalPages
        );
        return { curPageNumber: pageNumber };
      }),
      assignNextPage: actions.assign((ctx, _event) => {
        const { curPageNumber, pageUrls } = ctx;
        const totalPages = pageUrls.length;
        const newPageNumber = curPageNumber + 1;
        const pageNumber = findInRangeValue(
          curPageNumber,
          newPageNumber,
          totalPages
        );
        return { curPageNumber: pageNumber };
      }),
      assignCurSentenceNumber: actions.assign((ctx, event) => {
        const { sentences, curPageNumber } = ctx;
        const index = sentences[curPageNumber].findIndex(
          (sentence: Sentence) => sentence.id === event.id
        );

        return { curSentenceNumber: index };
      }),
      assignSelectionText: actions.assign((ctx, event) => {
        const { selection } = ctx;

        const updatedSelection = { ...selection, text: event.text?.trim() };
        return { selection: updatedSelection };
      }),
      assignSelectionScore: actions.assign((ctx, event) => {
        const { selection } = ctx;

        const updatedSelection = {
          ...selection,
          severity_score: event.severity_score,
        };
        return { selection: updatedSelection };
      }),
      redoHighlight: actions.assign((ctx, _event) => {
        const {
          sentenceCache,
          allSentences,
          sentences,
          curPageNumber,
          curSentenceNumber,
        } = ctx;

        const updatedPageData = [...sentences[curPageNumber]];
        let updatedSentenceNumber = curSentenceNumber;
        let updatedSentenceData;
        if (sentenceCache !== null) {
          // add back user-ranked non-problematic sentence into list of
          // problematic/unreviewed/unsure sentences (model or user ranked)
          updatedSentenceData = {
            ...sentenceCache.sentence,
            info: {
              ...sentenceCache.sentence.info,
              user_problematic: UserProblematic.UNREVIEWED,
              severity_score: SeverityScore.UNREVIEWED,
            },
          };

          updatedPageData.splice(sentenceCache.index, 0, updatedSentenceData);
          updatedSentenceNumber = sentenceCache.index;
        } else {
          // overwrite problematic/unsure sentence info as unreviewed
          const origSentenceData = sentences[curPageNumber][curSentenceNumber];
          updatedSentenceData = {
            ...origSentenceData,
            info: {
              ...origSentenceData.info,
              user_problematic: UserProblematic.UNREVIEWED,
              severity_score: SeverityScore.UNREVIEWED,
            },
          };
          updatedPageData.splice(curSentenceNumber, 1, updatedSentenceData);
        }

        const newAllPageData = updateAllSentences(
          updatedSentenceData.id,
          allSentences,
          curPageNumber
        );
        const newAllSentences = updateSentences(
          allSentences,
          curPageNumber,
          newAllPageData
        );

        const updatedSentences = [...sentences];
        updatedSentences[curPageNumber] = updatedPageData;

        return {
          allSentences: newAllSentences,
          sentences: updatedSentences,
          curSentenceNumber: updatedSentenceNumber,
        };
      }),
      deleteHighlight: actions.assign((ctx, event) => {
        const {
          selection,
          allSentences,
          sentences,
          curPageNumber,
          curSentenceNumber,
        } = ctx;

        let updatedSentences = [...sentences];
        let updatedSentenceNumber = curSentenceNumber;
        if (
          (Boolean(selection?.text) && Boolean(selection?.severity_score)) ||
          sentences[curPageNumber].find(
            (sentence: Sentence) => sentence.id === event.id
          )
        ) {
          let updatedPageData = sentences[curPageNumber].filter(
            (sentenceData: Sentence) => {
              return sentenceData.id !== event.id;
            }
          );
          updatedSentences[curPageNumber] = updatedPageData;
          updatedSentenceNumber = findInRangeValue(
            curSentenceNumber,
            curSentenceNumber - 1,
            sentences[curPageNumber].length
          );
        }

        const newAllSentences = markNonProblematicSentence(
          event.id,
          allSentences,
          curPageNumber
        );
        return {
          allSentences: newAllSentences,
          sentences: updatedSentences,
          curSentenceNumber: updatedSentenceNumber,
        };
      }),
      updateHighlight: actions.assign((ctx, event) => {
        const { allSentences, sentences, curPageNumber } = ctx;

        const newAllSentences = updatePositionAndContent(
          event,
          allSentences,
          curPageNumber
        );
        const newSentences = updatePositionAndContent(
          event,
          sentences,
          curPageNumber
        );

        return { allSentences: newAllSentences, sentences: newSentences };
      }),
      addHighlight: actions.assign((ctx, event) => {
        const { pageUrls, allSentences, sentences, curPageNumber, selection } =
          ctx;

        const newAllSentences = updateHighlights(
          allSentences,
          curPageNumber,
          selection,
          pageUrls[curPageNumber]["id"],
          event.position
        );

        const newSentences = updateHighlights(
          sentences,
          curPageNumber,
          selection,
          pageUrls[curPageNumber]["id"],
          event.position
        );
        return {
          allSentences: newAllSentences,
          sentences: newSentences,
          curSentenceNumber: newSentences[curPageNumber].length - 1,
        };
      }),
      assignProblematic: actions.assign((ctx, event) => {
        const {
          allSentences,
          sentences,
          sentenceCache,
          curPageNumber,
          curSentenceNumber,
        } = ctx;

        let newSentenceCache = sentenceCache;
        let newSentenceNumber = curSentenceNumber;

        const updatedAllPageData = updateProblematicPageData(
          event,
          allSentences,
          curPageNumber
        );
        const updatedPageData = updateProblematicPageData(
          event,
          sentences,
          curPageNumber
        );

        const index = updatedPageData.findIndex((sentenceData: Sentence) => {
          return sentenceData.info.user_problematic === UserProblematic.NO;
        });
        if (index !== -1) {
          newSentenceNumber = findInRangeValue(
            curSentenceNumber,
            curSentenceNumber - 1,
            sentences[curPageNumber].length
          );
          newSentenceCache = { sentence: updatedPageData[index], index: index };
          updatedPageData.splice(index, 1);
        }

        const newAllSentences = updateSentences(
          allSentences,
          curPageNumber,
          updatedAllPageData
        );
        const newSentences = updateSentences(
          sentences,
          curPageNumber,
          updatedPageData
        );

        return {
          allSentences: newAllSentences,
          sentences: newSentences,
          curSentenceNumber: newSentenceNumber,
          sentenceCache: newSentenceCache,
        };
      }),
      assignSeverityScore: actions.assign((ctx, event) => {
        const { allSentences, sentences, curPageNumber } = ctx;

        const newAllPageData = updateSeverityPageData(
          event,
          allSentences,
          curPageNumber
        );
        const newAllSentences = updateSentences(
          allSentences,
          curPageNumber,
          newAllPageData
        );

        const newPageData = updateSeverityPageData(
          event,
          sentences,
          curPageNumber
        );
        const newSentences = updateSentences(
          sentences,
          curPageNumber,
          newPageData
        );
        return {
          allSentences: newAllSentences,
          sentences: newSentences,
        };
      }),
      assignError: actions.assign((_ctx, event) => {
        return { error: event };
      }),
      clearError: actions.assign((_ctx, _event) => {
        return { error: null };
      }),
      setAllActive: actions.assign((ctx, _event) => {
        const { sentences, curPageNumber } = ctx;
        const updatedSentences = [...sentences];
        const updatedPageData = updatedSentences[curPageNumber].map(
          (sentence: Sentence) => {
            return { ...sentence, isActive: true };
          }
        );
        updatedSentences[curPageNumber] = updatedPageData;
        return { sentences: updatedSentences };
      }),
      resetSentenceCache: actions.assign((_ctx, _event) => {
        return { sentenceCache: null };
      }),
      resetCurPageNumber: actions.assign((_ctx, _event) => {
        return { curPageNumber: 0 };
      }),
      resetCurSentenceNumber: actions.assign((_ctx, _event) => {
        return { curSentenceNumber: 0 };
      }),
      resetSelection: actions.assign((_ctx, _event) => {
        return { selection: undefined };
      }),
      resetContext: actions.assign((_ctx, _event) => {
        return {
          isReviewing: false,
          docId: undefined,
          uploadedDoc: null,
          curPageNumber: 0,
          totalPages: 0,
          pageUrls: [],
          curSentenceNumber: 0,
          allSentences: [],
          sentences: [],
          sentenceCache: null,
          selection: null,
          exportDoc: null,
          error: null,
        };
      }),
    },
    guards: {
      hasResponse: (ctx, _event) => {
        return Boolean(ctx.pageUrls) && Boolean(ctx.sentences);
      },
    },
  }
);

const isSelectionText = (sentence: Sentence) => {
  return sentence.id === undefined;
};

const setSelectionTextVariables = (
  document_id: string | undefined,
  sentence: Sentence
) => {
  const rectangles = sentence.position.rects.map((rectangle: Rectangle) => {
    return {
      x0: rectangle.x1,
      y0: rectangle.y1,
      x1: rectangle.x2,
      y1: rectangle.y2,
    };
  });

  const variables: feedbackVariables = {
    data: {
      document_id: document_id,
      page_id: sentence.page_id,
      user_defined: true,
      text: sentence.content.text,
      problematic:
        sentence.info.user_problematic === UserProblematic.YES ? true : false,
      severity_score: sentence.info.severity_score,
      rectangles: rectangles,
    },
  };

  return variables;
};

const setFeedbackVariables = (
  document_id: string | undefined,
  sentence: Sentence
) => {
  const rectangles = sentence.position.rects.map((rectangle: Rectangle) => {
    return {
      x0: rectangle.x1,
      y0: rectangle.y1,
      x1: rectangle.x2,
      y1: rectangle.y2,
    };
  });

  const variables: feedbackVariables = {
    data: {
      document_id: document_id,
      page_id: sentence.page_id,
      sentence_id: sentence.id,
      user_defined: true,
      text: sentence.content.text,
      problematic:
        sentence.info.user_problematic === UserProblematic.YES ? true : false,
      severity_score: sentence.info.severity_score,
      rectangles: rectangles,
    },
  };

  return variables;
};

const findInRangeValue = (value: number, newValue: number, max: number) => {
  return 0 <= newValue && newValue < max ? newValue : value;
};

const isInRange = (value: number, max: number) => {
  return 0 <= value && value < max;
};

const markNonProblematicSentence = (
  id: string | undefined,
  allSentences: any,
  curPageNumber: number
) => {
  const updatedPageData = allSentences[curPageNumber].map(
    (sentenceData: Sentence) => {
      if (sentenceData.id === id) {
        return {
          ...sentenceData,
          info: {
            ...sentenceData.info,
            user_problematic: UserProblematic.NO,
            SeverityScore: SeverityScore.UNREVIEWED,
          },
        };
      } else {
        return sentenceData;
      }
    }
  );

  return updateSentences(allSentences, curPageNumber, updatedPageData);
};

const updatePositionAndContent = (
  event: any,
  sentences: any,
  curPageNumber: number
) => {
  let updatedPageData = sentences[curPageNumber].map(
    (sentenceData: Sentence) => {
      const {
        position: origPosition,
        content: origContent,
        ...rest
      } = sentenceData;
      return sentenceData.id === event.id
        ? {
            ...sentenceData,
            position: { ...origPosition, ...event.position },
            content: { ...origContent, ...event.content },
            ...rest,
          }
        : sentenceData;
    }
  );

  return updateSentences(sentences, curPageNumber, updatedPageData);
};
const updateProblematicPageData = (
  event: any,
  sentences: any,
  curPageNumber: number
) => {
  const updatedPageData = sentences[curPageNumber].map(
    (sentenceData: Sentence) => {
      if (sentenceData.id === event.id) {
        return {
          ...sentenceData,
          info: {
            ...sentenceData.info,
            user_problematic: event.user_problematic,
          },
        };
      } else {
        return sentenceData;
      }
    }
  );

  return updatedPageData;
};

const updateSeverityPageData = (
  event: any,
  sentences: any,
  curPageNumber: number
) => {
  const updatedPageData = sentences[curPageNumber].map(
    (sentenceData: Sentence) => {
      if (sentenceData.id === event.id) {
        return {
          ...sentenceData,
          info: {
            ...sentenceData.info,
            severity_score: event.severity_score,
          },
        };
      } else {
        return sentenceData;
      }
    }
  );

  return updatedPageData;
};

const updateAllSentences = (
  sentence: Sentence,
  allSentences: any,
  curPageNumber: number
) => {
  const updatedPageData = allSentences[curPageNumber].map(
    (sentenceData: Sentence) => {
      if (sentenceData.id === sentence.id) {
        return sentence;
      } else {
        return sentenceData;
      }
    }
  );

  return updatedPageData;
};

const updateSentences = (
  sentences: any,
  curPageNumber: number,
  updatedPageData: Sentence[]
) => {
  const updatedSentences = [...sentences];
  updatedSentences[curPageNumber] = updatedPageData;

  return updatedSentences;
};

const updateHighlights = (
  sentences: any,
  curPageNumber: number,
  selection: Selection | null,
  pageId: string,
  position: ScaledPosition
) => {
  const pageData = sentences[curPageNumber];

  // Look if the list of sentences already contains this sentence
  const found = pageData.some(
    (sentence: Sentence) => sentence.content.text === selection?.text
  );

  let newSentence = {};
  if (found === true) {
    // Find the index and update the sentence at that index
    const index = pageData.findIndex(
      (sentence: Sentence) => sentence.content.text === selection?.text
    );

    newSentence = {
      ...pageData[index],
      isActive: true,
      info: {
        ...pageData[index]["info"],
        user_defined: true,
        user_problematic: UserProblematic.YES,
        severity_score: selection?.severity_score
          ? selection?.severity_score
          : SeverityScore.UNREVIEWED,
      },
    };
    pageData[index] = newSentence;
  } else {
    // Create then push a new sentence
    newSentence = {
      isActive: true,
      id: undefined,
      page_id: pageId,
      content: { text: selection?.text },
      position: { ...position, usePdfCoordinates: true },
      info: {
        user_defined: true,
        model_problematic: false,
        user_problematic: UserProblematic.YES,
        severity_score: selection?.severity_score
          ? selection?.severity_score
          : SeverityScore.UNREVIEWED,
      },
    };
    pageData.push(newSentence);
  }

  const updatedSentences = [...sentences];
  updatedSentences[curPageNumber] = pageData;

  return updatedSentences;
};
