/** @jsxImportSource @emotion/react */
import { useNavigate } from "react-router-dom";
import { useSelector } from "@xstate/react";
import { Classes, NavbarProps } from "@blueprintjs/core";
import { Button, Navbar, NavbarHeading, NavbarGroup } from "@blueprintjs/core";
import { css } from "@emotion/react";

import { AuthMachineState } from "../stateMachines/auth.machine";
import { useAppStateContext } from "../utils/appState";
import { useUploadStateContext } from "../utils/uploadState";

import { colors } from "../tokens";

const isAuthenticatedSelector = (state: AuthMachineState) => {
  return state.matches("authenticated");
};

export const MenuBar: React.FC<NavbarProps> = () => {
  const { uploadService } = useUploadStateContext();
  const { send: sendUpload } = uploadService;

  const { authService } = useAppStateContext();
  const { send: sendAuth } = authService;

  const isAuthenticated = useSelector(authService, isAuthenticatedSelector);

  const navigate = useNavigate();

  return (
    <Navbar css={divStyles} fixedToTop>
      <NavbarGroup>
        <NavbarHeading>
          <img
            css={logoStyles}
            src={`${process.env.PUBLIC_URL}/RiskReviewLogo - White.svg`}
            alt="RiskReview White Logo"
          />
        </NavbarHeading>
        <Button
          className={Classes.MINIMAL}
          icon="home"
          text="Home"
          onClick={() => {
            sendUpload({ type: "NAV_AWAY" });
            navigate(`/`);
          }}
        />
      </NavbarGroup>
      {isAuthenticated && (
        <NavbarGroup align={"right"}>
          <Button
            className={Classes.MINIMAL}
            icon="log-out"
            text="Logout"
            onClick={() => {
              sendUpload({ type: "NAV_AWAY" });
              sendAuth({ type: "LOGOUT" });
              navigate(`/`);
            }}
          />
        </NavbarGroup>
      )}
    </Navbar>
  );
};

const divStyles = css`
  background: ${colors.primary1};

  a,
  span,
  svg,
  div {
    color: white;
  }

  img {
    width: 120px;
    height: 120px;
  }
`;

const logoStyles = css`
  margin-top: 5px;
`;
