import { useNavigate } from "react-router-dom";
import { Intent, NonIdealStateIconSize } from "@blueprintjs/core";
import { Button, NonIdealState } from "@blueprintjs/core";

import { useUploadStateContext } from "../../utils/uploadState";

export const ErrorUploading: React.FC = () => {
  const { uploadService } = useUploadStateContext();
  const { send } = uploadService;

  const navigate = useNavigate();

  return (
    <NonIdealState
      icon="error"
      iconSize={NonIdealStateIconSize.STANDARD}
      title="Error during upload!"
      description="Try re-uploading your document"
      action={
        <Button
          intent={Intent.PRIMARY}
          icon="upload"
          text="Upload"
          onClick={() => {
            send({ type: "NAV_AWAY" });
            navigate(`/upload`);
          }}
        />
      }
    />
  );
};
