import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "@xstate/react";
import {
  Intent,
  Spinner,
  SpinnerSize,
  OverlayToaster,
} from "@blueprintjs/core";

import { AuthMachineState } from "./stateMachines/auth.machine";
import { useAppStateContext } from "./utils/appState";
import { MenuBar } from "./components/MenuBar";
import { LoginView } from "./views/LoginView";
import { ForgotPasswordView } from "./views/ForgotPasswordView";
import { UploadView } from "./views/UploadView";
import { ErrorView } from "./views/ErrorView";
import { PreviewView } from "./views/PreviewView";
import { ReviewView } from "./views/ReviewView";

const isUnauthenticatedSelector = (state: AuthMachineState) => {
  return state.matches("unauthenticated");
};
const isAuthenticatingSelector = (state: AuthMachineState) => {
  return state.matches("authenticating");
};
const isAuthenticatedSelector = (state: AuthMachineState) => {
  return state.matches("authenticated");
};
const prevStateSelector = (state: AuthMachineState) => {
  return state.history?.historyValue?.current;
};

const toaster = OverlayToaster.create({ position: "top" });

export const App = () => {
  const { authService } = useAppStateContext();
  const isUnauthenticated = useSelector(authService, isUnauthenticatedSelector);
  const isAuthenticating = useSelector(authService, isAuthenticatingSelector);
  const isAuthenticated = useSelector(authService, isAuthenticatedSelector);
  const prevState = useSelector(authService, prevStateSelector);

  useEffect(() => {
    if (isUnauthenticated && prevState === "authenticating") {
      showFailureToast();
    }
    if (isAuthenticated && prevState === "authenticating") {
      showSuccessToast();
    }
  }, [isUnauthenticated, isAuthenticated, prevState]);

  const showSuccessToast = () => {
    toaster.show({
      intent: Intent.SUCCESS,
      icon: "tick-circle",
      message: "Login successful!",
      timeout: 3000,
      isCloseButtonShown: true,
    });
  };

  const showFailureToast = () => {
    toaster.show({
      intent: Intent.DANGER,
      icon: "error",
      message: "Login failed. Please try again with different credentials.",
      timeout: 3000,
      isCloseButtonShown: true,
    });
  };

  return (
    <BrowserRouter>
      <React.Suspense
        fallback={<Spinner className="centered" size={SpinnerSize.LARGE} />}
      >
        {(isUnauthenticated || isAuthenticating) && (
          <Routes>
            <Route path={"/"} element={<LoginView />} />
            <Route path={"/forgot-password"} element={<ForgotPasswordView />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        )}
        {isAuthenticated && (
          <>
            <MenuBar />
            <Routes>
              <Route path={"/"} element={<UploadView />} />
              <Route path={"/preview/:fileId"} element={<PreviewView />} />
              <Route path={"/review/:fileId"} element={<ReviewView />} />
              <Route path={"/error"} element={<ErrorView />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </>
        )}
      </React.Suspense>
    </BrowserRouter>
  );
};
