/** @jsxImportSource @emotion/react */
import { useNavigate } from "react-router";
import { useSelector } from "@xstate/react";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  Intent,
  Spinner,
} from "@blueprintjs/core";
import { css } from "@emotion/react";

import { ReviewMachineState } from "../../stateMachines/review.machine";
import { useReviewStateContext } from "../../utils/reviewState";
import { useUploadStateContext } from "../../utils/uploadState";

const isFinishingSelector = (state: ReviewMachineState) => {
  return state.matches("finishing");
};
const isFetchingExportSelector = (state: ReviewMachineState) => {
  return state.matches("fetchingExport");
};

export const ExportDialog: React.FC = (props) => {
  const { uploadService } = useUploadStateContext();
  const { send: uploadSend } = uploadService;

  const { reviewService } = useReviewStateContext();
  const { send: reviewSend } = reviewService;
  const isFinishing = useSelector(reviewService, isFinishingSelector);
  const isFetchingExport = useSelector(reviewService, isFetchingExportSelector);

  const navigate = useNavigate();

  const renderFooterButtons = () => {
    return (
      <>
        <Button
          text="No, don't export"
          onClick={() => {
            uploadSend({ type: "CANCEL" });
            reviewSend({ type: "CANCEL" });
            navigate(`/`);
          }}
        />
        <Button
          icon="export"
          text="Yes, finish and export PDF"
          intent={Intent.PRIMARY}
          onClick={() => {
            uploadSend({ type: "NAV_AWAY" });
            reviewSend({ type: "EXPORT" });
          }}
        />
      </>
    );
  };

  return (
    <Dialog
      icon="warning-sign"
      title="Before you leave"
      isOpen={isFinishing || isFetchingExport}
      onClose={() => reviewSend({ type: "CLOSE" })}
      autoFocus={true}
      enforceFocus={true}
      shouldReturnFocusOnClose={true}
      isCloseButtonShown={!isFetchingExport}
      canEscapeKeyClose={!isFetchingExport}
      canOutsideClickClose={!isFetchingExport}
      usePortal={true}
    >
      <DialogBody useOverflowScrollContainer={false}>
        {isFetchingExport ? (
          <div css={LoadingStyles}>
            <Spinner />
            <br />
            <p>Fetching export...</p>
          </div>
        ) : (
          <p>Would you like to export your work as a PDF?</p>
        )}
      </DialogBody>
      <DialogFooter
        minimal={true}
        actions={!isFetchingExport && renderFooterButtons()}
      />
    </Dialog>
  );
};

const LoadingStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
