/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "@xstate/react";
import { Classes, Tooltip2 } from "@blueprintjs/popover2";
import { Position, SpinnerSize } from "@blueprintjs/core";
import { Button, Collapse, H4, Icon, Spinner } from "@blueprintjs/core";
import { css } from "@emotion/react";

import { AuthMachineState } from "../../stateMachines/auth.machine";
import { useAppStateContext } from "../../utils/appState";
import { UploadMachineState } from "../../stateMachines/upload.machine";
import { useUploadStateContext } from "../../utils/uploadState";
import { colors } from "../../tokens";

const isAuthenticatedSelector = (state: AuthMachineState) => {
  return state.matches("authenticated");
};
const docIdSelector = (state: UploadMachineState) => {
  return state.context.savedDoc?.document_id;
};
const hasSavedDocSelector = (state: UploadMachineState) => {
  return state.context.hasSavedDoc;
};
const savedDocSelector = (state: UploadMachineState) => {
  return state.context.savedDoc;
};
const isFinishingSelector = (state: UploadMachineState) => {
  return state.matches("finishing.reviewing");
};
const isErroringSelector = (state: UploadMachineState) => {
  return state.matches("idle.errored");
};
const isFetchingSavedDocSelector = (state: UploadMachineState) => {
  return (
    state.matches("fetchingSavedDoc") || state.matches("fetchingSavedDocInfo")
  );
};

export const SavedDocuments: React.FC = () => {
  const { authService } = useAppStateContext();
  const isAuthenticated = useSelector(authService, isAuthenticatedSelector);

  const { uploadService } = useUploadStateContext();
  const { send: sendUpload } = uploadService;
  const docId = useSelector(uploadService, docIdSelector);
  const hasSavedDoc = useSelector(uploadService, hasSavedDocSelector);
  const savedDoc = useSelector(uploadService, savedDocSelector);
  const isFinishing = useSelector(uploadService, isFinishingSelector);
  const isErroring = useSelector(uploadService, isErroringSelector);
  const isFetchingSavedDoc = useSelector(
    uploadService,
    isFetchingSavedDocSelector
  );

  const editDate =
    hasSavedDoc && savedDoc
      ? convertDateToString(savedDoc.last_edit_time)
      : null;
  const editTime =
    hasSavedDoc && savedDoc
      ? convertTimeToString(savedDoc.last_edit_time)
      : null;

  const [isOpen, setIsOpen] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      sendUpload({ type: "LOGIN" });
    }
  }, [isAuthenticated, sendUpload]);

  useEffect(() => {
    if (isFinishing) {
      navigate(`/review/${docId}`);
    }
    if (isErroring) {
      navigate(`/error`);
    }
  }, [navigate, docId, isFinishing, isErroring]);

  const editHandler = () => {
    sendUpload("RESUME");
  };

  return (
    <div css={DropdownStyles}>
      <div css={ContentStyles}>
        <div
          css={HeaderStyles}
          role="switch"
          aria-checked={isOpen}
          onClick={() => setIsOpen((prevState) => !prevState)}
        >
          {isFetchingSavedDoc ? (
            <>
              <div>
                <H4>Fetching Saved Document...</H4>
              </div>
              <Spinner size={SpinnerSize.SMALL} />
            </>
          ) : (
            <>
              <div css={TitleStyles}>
                <H4>Your Document</H4>
                <Tooltip2
                  className={Classes.TOOLTIP2_INDICATOR}
                  position={Position.RIGHT}
                  content="You can only edit the last file in progress."
                >
                  <Icon icon="small-info-sign" />
                </Tooltip2>
              </div>
              <Icon
                css={isOpen ? [CaretStyles, RotateStyles] : CaretStyles}
                icon="key-control"
              />
            </>
          )}
        </div>
        {!isFetchingSavedDoc && (
          <>
            {isOpen && <br />}
            <Collapse isOpen={isOpen}>
              <div css={FileStyles}>
                {hasSavedDoc ? (
                  <>
                    <div css={FilenameStyles}>
                      <p>Document {savedDoc?.document_name}</p>
                      <Button minimal onClick={editHandler}>
                        edit
                      </Button>
                    </div>
                    <p>
                      This document was last edited on {editDate} at {editTime}.
                    </p>
                    <p />
                  </>
                ) : (
                  <p>No saved document was found</p>
                )}
              </div>
            </Collapse>
          </>
        )}
      </div>
    </div>
  );
};

const DropdownStyles = css`
  min-width: 400px;
  height: fit-content;

  border-radius: 1rem;
  border: solid;
  border-width: 1px;
  border-color: ${colors.primary5};
  background-color: ${colors.primary6};

  h4,
  span {
    color: ${colors.primary2};
  }

  h4 {
    margin-top: 8px;
    margin-right: 12px;
    width: max-content;
  }
`;

const ContentStyles = css`
  margin: 8px 24px;
`;

const HeaderStyles = css`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FileStyles = css`
  padding-bottom: 4px;
`;

const CaretStyles = css`
  transition: transform 200ms;
`;

const RotateStyles = css`
  transform: rotate(180deg);
`;

const FilenameStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const convertDateToString = (time: Date) => {
  return time.toLocaleString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};
const convertTimeToString = (time: Date) => {
  return time.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};
