/** @jsxImportSource @emotion/react */
import { SavedDocuments } from "../components/upload/SavedDocuments";
import { UploadButton } from "../components/upload/UploadButton";
import { css } from "@emotion/react";

interface UploadViewProps {
  css?: any;
}

export const UploadView: React.FC<UploadViewProps> = () => {
  return (
    <div css={UploadWrapper} className="row">
      <SavedDocuments />
      <div css={UploadButtonWrapper}>
        <UploadButton />
      </div>
    </div>
  );
};

const UploadWrapper = css`
  margin-left: 20px;
  margin-top: 40px;
`;

const UploadButtonWrapper = css`
  width: 100%;
  margin-left: 80px;
  margin-right: 80px;
  margin-top: 120px;
`;
