/** @jsxImportSource @emotion/react */
import { Classes, Intent, Position } from "@blueprintjs/core";
import { Button, Drawer } from "@blueprintjs/core";
import { css } from "@emotion/react";

interface DisclaimerProps {
  showDisclaimer: boolean;
  setShowDisclaimer: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Disclaimer: React.FC<DisclaimerProps> = ({
  showDisclaimer,
  setShowDisclaimer,
}) => {
  const toggleDisclaimer = () => {
    setShowDisclaimer((prevState) => !prevState);
  };

  return (
    <Drawer
      icon="warning-sign"
      title="Disclaimer"
      position={Position.TOP}
      isOpen={showDisclaimer}
      isCloseButtonShown={false}
    >
      <div className={Classes.DRAWER_BODY}>
        <div className={Classes.DIALOG_BODY}>
          <p>
            Welcome to RiskReview, an online tool designed to help you assess
            and manage risks effectively. RiskReview employs machine learning to
            assist research administrators and contracting professionals review
            various contractual documents for problematic terms that may present
            risks of loss of intellectual property rights. Understanding that
            your data is valuable and potentially sensitive, efforts have been
            taken to safeguard it. Please read this disclaimer carefully to
            understand how the tool handles the data you upload to the platform
            and your ultimate responsibility for your risk assessments and
            resulting decisions.
          </p>
          <ol>
            <li>Data Privacy Assurance</li>
            <p>
              Any document you upload onto the platform is treated with the
              utmost confidentiality and respect. The tool does not, under any
              circumstances, use your data for any commercial purposes,
              including advertising or marketing. Furthermore, your data will
              not be shared with any other users or entities, whether
              individuals, organizations, or third-party services. Your
              information is exclusively used within the context of the
              RiskReview platform to train the risk assessment model.
            </p>
            <li>Data Security</li>
            <p>
              RiskReview employs industry-standard security measures to protect
              your data from unauthorized access, disclosure, or alteration.
              Robust security protocols and encryption techniques are in place
              to ensure that your information remains confidential and secure.
            </p>
            <li>Data Retention</li>
            <p>
              Your data is retained only for as long as it is necessary for your
              analysis and for training the risk assessment model. When your
              data is no longer needed, administrators will take the necessary
              steps to ensure its secure disposal.
            </p>
            <li>No legal advice</li>
            <p>
              RiskReview is an analytical tool and does not provide legal advice
              or substitute for professional legal consultation. Your risk
              assessments, and any resulting decisions you make using RiskReview
              remain your responsibility.
            </p>
          </ol>
          <p>
            By using the RiskReview platform, you agree to the terms outlined in
            this disclaimer regarding the usage and protection of your data and
            acknowledge that RiskReview does not provide legal advice. If you
            have any concerns or questions about our data handling practices,
            please do not hesitate to contact us.
          </p>
          <p>
            This material was prepared as an account of work sponsored by an
            agency of the United States Government. Neither the United States
            Government nor the United States Department of Energy, nor the
            Contractor, nor any or their employees, nor any jurisdiction or
            organization that has cooperated in the development of these
            materials, makes any warranty, express or implied, or assumes any
            legal liability or responsibility for the accuracy, completeness, or
            usefulness or any information, apparatus, product, software, or
            process disclosed, or represents that its use would not infringe
            privately owned rights.
          </p>
          <p>
            Reference herein to any specific commercial product, process, or
            service by trade name, trademark, manufacturer, or otherwise does
            not necessarily constitute or imply its endorsement, recommendation,
            or favoring by the United States Government or any agency thereof,
            or Battelle Memorial Institute. The views and opinions of authors
            expressed herein do not necessarily state or reflect those of the
            United States Government or any agency thereof.
          </p>
          <p css={AddressStyles}>
            PACIFIC NORTHWEST NATIONAL LABORATORY
            <br />
            operated by
            <br />
            BATTELLE
            <br />
            for the
            <br />
            UNITED STATES DEPARTMENT OF ENERGY
            <br />
            under Contract DE-AC05-76RL01830
          </p>
        </div>

        <div css={ButtonStyles} className={Classes.DRAWER_FOOTER}>
          <Button
            intent={Intent.PRIMARY}
            icon="chevron-up"
            onClick={toggleDisclaimer}
          />
        </div>
      </div>
    </Drawer>
  );
};

const ButtonStyles = css`
  display: flex;
  justify-content: center;
`;

const AddressStyles = css`
  display: flex;
  justify-content: center;
  text-align: center;
`;
