/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "@xstate/react";
import { Button, ButtonGroup, InputGroup, Spinner } from "@blueprintjs/core";
import { Classes, Position } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import { FormattedMessage } from "react-intl";
import {
  AreaHighlight,
  Highlight,
  PdfHighlighter,
  PdfLoader,
  Popup,
  SeverityScore,
  Tip,
} from "@pham740/react-pdf-highlighter";
import { css } from "@emotion/react";

import { ReviewMachineState } from "../../stateMachines/review.machine";
import { useReviewStateContext } from "../../utils/reviewState";

import { colors } from "../../tokens";
import "./PdfViewer.css";

const isReviewingSelector = (state: ReviewMachineState) => {
  return state.matches("reviewing");
};
const isPostingHighlightsSelector = (state: ReviewMachineState) => {
  return (
    state.matches("postingSelectionText") || state.matches("postingFeedback")
  );
};
const isHighlightingSelector = (state: ReviewMachineState) => {
  return (
    state.matches("reviewing.highlightingMode") ||
    state.matches("postingSelectionText")
  );
};
const isFinishingSelector = (state: ReviewMachineState) => {
  return state.matches("finishing") || state.matches("fetchingExport");
};
const pageUrlsSelector = (state: ReviewMachineState) => {
  return state.context.pageUrls;
};
const sentencesSelector = (state: ReviewMachineState) => {
  return state.context.sentences;
};
const curPageNumberSelector = (state: ReviewMachineState) => {
  return state.context.curPageNumber;
};

interface PdfViewerProps {
  isHighlightable: boolean;
  type: string;
}

export const PdfViewer: React.FC<PdfViewerProps> = ({
  type,
  isHighlightable,
}) => {
  const { reviewService } = useReviewStateContext();
  const { send } = reviewService;
  const isReviewing = useSelector(reviewService, isReviewingSelector);
  const isPosting = useSelector(reviewService, isPostingHighlightsSelector);
  const isHighlighting = useSelector(reviewService, isHighlightingSelector);
  const isFinishing = useSelector(reviewService, isFinishingSelector);
  const pageUrls = useSelector(reviewService, pageUrlsSelector);
  const sentences = useSelector(reviewService, sentencesSelector);
  const curPageNumber = useSelector(reviewService, curPageNumberSelector);

  const totalPages = pageUrls.length;
  const docType = type === "pdf" ? "s3_raw_url" : "s3_ocr_url";
  const highlights =
    (isReviewing || isPosting || isFinishing) && isHighlightable
      ? sentences[curPageNumber]
      : [];

  const [pageNumber, setPageNumber] = useState(curPageNumber);
  const navigate = useNavigate();

  useEffect(() => {
    setPageNumber(curPageNumber + 1);
  }, [curPageNumber]);

  useEffect(() => {
    if (pageUrls[curPageNumber][docType] === undefined) {
      navigate(`/`);
    }
  }, [navigate, pageUrls, curPageNumber, docType]);

  const parseIdFromHash = () => document.location.hash.slice();
  document.location.hash.slice("#highlight-".length);

  const resetHash = () => {
    document.location.hash = "";
  };

  let scrollViewerTo = (highlight: any) => {};

  const scrollToHighlightFromHash = () => {
    const highlight = getHighlightById(parseIdFromHash());

    if (highlight) {
      scrollViewerTo(highlight);
    }
  };

  const getHighlightById = (id: string) => {
    return highlights.find((highlight: any) => highlight.id === id);
  };

  const updateHighlight = (id: string, position: Object, content: Object) => {
    send({
      type: "UPDATE_HIGHLIGHT",
      id: id,
      position: position,
      content: content,
    });
  };

  return (
    <div css={WrapperStyles}>
      <div
        css={css`
          ${(!isHighlightable || !isHighlighting) && PreventSelection};
          ${DivStyles};
        `}
      >
        <PdfLoader
          url={pageUrls[curPageNumber][docType]}
          beforeLoad={<Spinner />}
        >
          {(pdfDocument) => (
            <PdfHighlighter
              pdfDocument={pdfDocument}
              enableAreaSelection={(event) => event.altKey}
              onScrollChange={resetHash}
              scrollRef={(scrollTo) => {
                scrollViewerTo = scrollTo;
                scrollToHighlightFromHash();
              }}
              onSelectionFinished={(
                position,
                content,
                hideTipAndSelection,
                transformSelection
              ) => {
                send({
                  type: "SET_SELECTION_TEXT",
                  text: content.text,
                  position: position,
                });
                return (
                  <Tip
                    onOpen={transformSelection}
                    onConfirm={(info) => {
                      send({
                        type: "SET_SELECTION_SCORE",
                        severity_score:
                          info.severity_score === "MID"
                            ? SeverityScore.MEDIUM
                            : SeverityScore[
                                info.severity_score as keyof typeof SeverityScore
                              ],
                        position: position,
                      });
                      hideTipAndSelection();
                    }}
                  />
                );
              }}
              highlightTransform={(
                highlight,
                index,
                setTip,
                hideTip,
                viewportToScaled,
                screenshot,
                isScrolledTo
              ) => {
                const isTextHighlight = !Boolean(
                  highlight.content && highlight.content.image
                );

                const component = isTextHighlight ? (
                  <Highlight
                    isScrolledTo={isScrolledTo}
                    highlight={highlight}
                    onClick={() => {
                      isHighlighting && send({ type: "TOGGLE" });
                      send({ type: "GO_TO_HIGHLIGHT", id: highlight.id });
                    }}
                  />
                ) : (
                  <AreaHighlight
                    isScrolledTo={isScrolledTo}
                    highlight={highlight}
                    onChange={(boundingRect) => {
                      updateHighlight(
                        highlight.id,
                        { boundingRect: viewportToScaled(boundingRect) },
                        { image: screenshot(boundingRect) }
                      );
                    }}
                  />
                );

                return (
                  <Popup
                    popupContent={<></>}
                    onMouseOver={(popupContent) =>
                      setTip(highlight, (highlight) => popupContent)
                    }
                    onMouseOut={hideTip}
                    key={index}
                    children={component}
                  />
                );
              }}
              highlights={highlights}
            />
          )}
        </PdfLoader>
      </div>
      <ButtonGroup className="PagingButtons">
        <div css={navButtonStyles}>
          <Button
            minimal
            icon="step-backward"
            disabled={curPageNumber + 1 <= 1}
            onClick={() => send({ type: "SET_PAGE", page_num: 0 })}
          />
          <Button
            minimal
            icon="arrow-left"
            disabled={curPageNumber + 1 <= 1}
            onClick={() => send({ type: "PREVIOUS_PAGE" })}
          />
          <div className="PagingButtons__middle">
            <FormattedMessage
              id="document.paging"
              defaultMessage="Page {pageInput} of {totalPages}"
              values={{
                pageInput: (
                  <form
                    className="PagingButtons__input"
                    onSubmit={(e) => {
                      e.preventDefault();
                      send({ type: "SET_PAGE", page_num: pageNumber - 1 });
                    }}
                    autoComplete="off"
                  >
                    <label htmlFor="page.input" />
                    <InputGroup
                      id="page.input"
                      className={Classes.ROUND}
                      onChange={(e) => setPageNumber(Number(e.target.value))}
                      value={String(pageNumber)}
                      type="number"
                      min={1}
                      max={totalPages}
                    />
                  </form>
                ),
                totalPages,
              }}
            />
          </div>
          <Button
            minimal
            icon="arrow-right"
            disabled={curPageNumber + 1 >= totalPages}
            onClick={() => send({ type: "NEXT_PAGE" })}
          />
          <Button
            minimal
            icon="step-forward"
            disabled={curPageNumber + 1 >= totalPages}
            onClick={() => send({ type: "SET_PAGE", page_num: totalPages - 1 })}
          />
        </div>
        {isHighlightable && (
          <Tooltip2
            css={highlightButtonStyles}
            content={"Add highlight"}
            position={Position.RIGHT}
          >
            <Button
              icon="highlight"
              large={true}
              onClick={() => send({ type: "TOGGLE" })}
              active={isHighlighting}
            />
          </Tooltip2>
        )}
      </ButtonGroup>
    </div>
  );
};

const PreventSelection = css`
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
`;

const DivStyles = css`
  height: 70vh;
  width: 100%;
  position: relative;
`;

const WrapperStyles = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const navButtonStyles = css`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

const highlightButtonStyles = css`
  .bp4-button:not([class*="bp4-intent-"]).bp4-active {
    background-color: ${colors.primary3};
    box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2),
      0 1px 2px rgba(17, 20, 24, 0.2);
  }
`;
