/** @jsxImportSource @emotion/react */
import { useSelector } from "@xstate/react";
import { Intent, Position } from "@blueprintjs/core";
import { Icon, Tab, Tabs } from "@blueprintjs/core";
import { Classes, Tooltip2 } from "@blueprintjs/popover2";
import { css } from "@emotion/react";

import { ReviewMachineState } from "../../stateMachines/review.machine";
import { useReviewStateContext } from "../../utils/reviewState";

const isHighlightingSelector = (state: ReviewMachineState) => {
  return state.matches("reviewing.highlightingMode");
};
const isReviewingSelector = (state: ReviewMachineState) => {
  return state.matches("reviewing");
};
const isPostingSelector = (state: ReviewMachineState) => {
  return (
    state.matches("postingSelectionText") || state.matches("postingFeedback")
  );
};
const isFinishingSelector = (state: ReviewMachineState) => {
  return (
    state.matches("finishing") ||
    state.matches("postingUnreviewedHighlightsOnCancel") ||
    state.matches("postingUnreviewedwHighlightsOnExport") ||
    state.matches("fetchingExport")
  );
};
interface DocumentTabsProps {
  pdfTab: JSX.Element;
  textTab: JSX.Element;
}

export const DocumentTabs: React.FC<DocumentTabsProps> = ({
  pdfTab,
  textTab,
}) => {
  const { reviewService } = useReviewStateContext();
  const { send } = reviewService;
  const isHighlighting = useSelector(reviewService, isHighlightingSelector);
  const isReviewing = useSelector(reviewService, isReviewingSelector);
  const isPosting = useSelector(reviewService, isPostingSelector);
  const isFinishing = useSelector(reviewService, isFinishingSelector);

  return (
    <Tabs
      id="document-tabs"
      animate={true}
      large={true}
      css={tabsStyles}
      renderActiveTabPanelOnly={true}
      onChange={(newTabId) => {
        newTabId === "pdf" && isHighlighting && send({ type: "TOGGLE" });
      }}
    >
      <Tab id="text" title="Text" panel={textTab} />
      <Tab id="pdf" title="PDF" panel={pdfTab} />
      {(isReviewing || isPosting || isFinishing) && (
        <Tooltip2
          className={Classes.TOOLTIP2_INDICATOR}
          position={Position.RIGHT}
          content="Clicking on a highlight in the PDF viewer will open the highlight in the Evaluation Section"
        >
          <Icon intent={Intent.PRIMARY} icon="info-sign" />
        </Tooltip2>
      )}
    </Tabs>
  );
};

const tabsStyles = css`
  width: 100%;
`;
