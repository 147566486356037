/** @jsxImportSource @emotion/react */
import { useSelector } from "@xstate/react";
import { Intent } from "@blueprintjs/core";
import { Button, Dialog, DialogBody, DialogFooter } from "@blueprintjs/core";

import { UploadMachineState } from "../../stateMachines/upload.machine";
import { useUploadStateContext } from "../../utils/uploadState";

const isShowingWarningSelector = (state: UploadMachineState) => {
  return state.matches("showingWarning");
};

interface UploadWarningDialogProps {}

export const UploadWarningDialog: React.FC<UploadWarningDialogProps> = (
  props
) => {
  const { uploadService } = useUploadStateContext();
  const { send } = uploadService;
  const isShowingWarning = useSelector(uploadService, isShowingWarningSelector);

  const cancelUploadHandler = () => {
    send({ type: "CANCEL" });
  };

  const continueUploadHandler = () => {
    send({ type: "RESUME" });
  };

  const renderFooterButtons = () => {
    return (
      <>
        <Button text="Cancel" onClick={cancelUploadHandler} />
        <Button
          icon="upload"
          text="Yes, upload document"
          intent={Intent.PRIMARY}
          onClick={continueUploadHandler}
        />
      </>
    );
  };

  return (
    <Dialog
      icon="warning-sign"
      title="Warning"
      isOpen={isShowingWarning}
      isCloseButtonShown={false}
    >
      <DialogBody>
        <p>
          <b>Please note: </b>This tool can only actively process one document
          at a time. Uploading a new document will overwrite your current saved
          document.
        </p>
        <p>Are you sure you want to proceed uploading?</p>
      </DialogBody>
      <DialogFooter minimal actions={renderFooterButtons()} />
    </Dialog>
  );
};
