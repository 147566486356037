/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const colors = {
  primary1: "#003242",
  primary2: "#026089",
  primary3: "#508fab",
  primary4: "#767676",
  primary5: "#D9D9D9",
  primary6: "#F5F5F5",
};

export const cssThemes = css`
  body {
    padding-top: 50px;
  }

  a {
    color: ${colors.primary2};
  }

  .bp4-toast {
    max-width: none;
  }

  .bp4-drawer {
    height: fit-content !important;
  }

  .bp4-callout {
    background-color: ${colors.primary6} !important;

    span,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: ${colors.primary2} !important;
    }
  }

  .bp4-tab[aria-selected="true"],
  .bp4-tab-indicator-wrapper {
    color: ${colors.primary1};
  }

  .bp4-tab-indicator-wrapper .bp4-tab-indicator {
    background-color: ${colors.primary1};
  }

  .bp4-tab-list {
    align-items: center;
  }

  .bp4-icon.bp4-intent-primary,
  .bp4-icon-standard.bp4-intent-primary,
  .bp4-icon-large.bp4-intent-primary {
    color: ${colors.primary2};
  }

  button:not(.bp4-minimal).bp4-button.bp4-intent-primary,
  a[role="button"]:not(.bp4-minimal).bp4-button.bp4-intent-primary {
    background-color: ${colors.primary2} !important;

    svg,
    span {
      color: white;
    }

    :disabled {
      background-color: ${colors.primary3} !important;
    }

    :hover:enabled {
      background-color: ${colors.primary1} !important;
    }

    .bp4-active {
      svg,
      span {
        color: ${colors.primary1};
      }
    }
  }

  .bp4-button.bp4-minimal.bp4-intent-primary {
    svg,
    span {
      color: ${colors.primary2};
    }

    :hover:enabled {
      background-color: ${colors.primary6} !important;
    }
  }
`;

export const cssClasses = css`
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }

  .row {
    display: flex;
    flex-direction: row;
  }

  .column {
    display: flex;
    flex-direction: column;
  }
`;
