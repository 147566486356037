import { fromPromise } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import { config } from "../config";

// Reference: https://stackoverflow.com/a/63386965

let isRefreshing = false;
let pendingRequests: Function[] = [];

const setIsRefreshing = (value: boolean) => {
  isRefreshing = value;
};

const addPendingRequest = (pendingRequest: Function) => {
  pendingRequests.push(pendingRequest);
};

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

const refreshTokens = async () => {
  await fetch(config.graphqlUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      query: `
        mutation ($user_id: UUID!) {
          user_refresh_jwt(user_id: $user_id) {
            access_jwt
            refresh_jwt
          }
        }
      `,
      variables: {
        user_id: localStorage.getItem("id"),
      },
    }),
  })
    .then((res) => res.json())
    .then((result) => {
      localStorage.setItem("token", result.data.user_refresh_jwt.refresh_jwt);
      sessionStorage.setItem("token", result.data.user_refresh_jwt.access_jwt);
    });
};

const errorLink = onError(({ operation, forward }) => {
  const { response } = operation.getContext();
  if (response.status === 401) {
    if (!isRefreshing) {
      setIsRefreshing(true);

      return fromPromise(
        refreshTokens().catch(() => {
          resolvePendingRequests();
          setIsRefreshing(false);

          // localStorage.clear();

          return forward(operation);
        })
      ).flatMap(() => {
        resolvePendingRequests();
        setIsRefreshing(false);

        return forward(operation);
      });
    } else {
      return fromPromise(
        new Promise<void>((resolve) => {
          addPendingRequest(() => resolve());
        })
      ).flatMap(() => {
        return forward(operation);
      });
    }
  }
});

export default errorLink;
