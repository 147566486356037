import { ApolloClient } from "@apollo/client";
import { ActorRefFrom, StateFrom, actions, createMachine } from "xstate";

import {
  GraphQlPage,
  GraphQlRectangle,
  GraphQlSentence,
  Page,
  getPdfResponse,
  postPdfResponse,
  SavedDoc,
  UploadedDoc,
} from "../utils/types";
import { SeverityScore, UserProblematic } from "@pham740/react-pdf-highlighter";
import {
  GET_SAVED_DOCUMENTS,
  GET_UPLOAD_URL,
  CREATE_DOC,
  GET_DOC,
} from "../graphql/graphql";

type UploadContext = {
  client: ApolloClient<object>;
  hasSavedDoc: boolean | null;
  savedDoc: SavedDoc | null;
  uploadedDoc: UploadedDoc | null;
  uploadUrl: string | null;
  curPageNumber: number;
  totalPages: number;
  pageUrls: Page[];
  curSentenceNumber: number;
  allSentences: any;
  sentences: any;
  error: any | null;
};

type UploadEvents =
  | { type: "LOGIN" }
  | {
      type: "SET_DOCUMENT";
      document_id: string;
      document_name: string;
      document_size: number;
      document: File;
    }
  | { type: "RESUME" }
  | { type: "CANCEL" }
  | { type: "NAV_AWAY" };

const getInitialState = (): Omit<UploadContext, "client"> => ({
  hasSavedDoc: null,
  savedDoc: null,
  uploadedDoc: null,
  uploadUrl: null,
  curPageNumber: 0,
  totalPages: 0,
  pageUrls: [],
  curSentenceNumber: 0,
  allSentences: [],
  sentences: [],
  error: null,
});

export type UploadMachineActor = ActorRefFrom<typeof uploadMachine>;
export type UploadMachineState = StateFrom<typeof uploadMachine>;

export const uploadMachine = createMachine(
  {
    tsTypes: {} as import("./upload.machine.typegen").Typegen0,
    schema: {
      context: {} as UploadContext,
      events: {} as UploadEvents,
      services: {} as {
        getSavedDoc: { data: any };
        getUploadUrl: { data: { createS3PostUrl: string } };
        postLargeDoc: { data: any };
        createDoc: { data: getPdfResponse };
        postDoc: { data: postPdfResponse };
      },
    },
    // @ts-expect-error
    context: { ...getInitialState() },
    initial: "uninitialized",
    states: {
      uninitialized: {
        on: {
          LOGIN: {
            actions: ["clearError", "resetContext"],
            target: "fetchingSavedDoc",
          },
        },
      },
      fetchingSavedDoc: {
        id: "fetchingSavedDoc",
        invoke: [
          {
            src: "getSavedDoc",
            onDone: {
              actions: "assignSavedDoc",
              target: "idle.noError",
            },
            onError: {
              actions: "assignError",
              target: "idle.errored",
            },
          },
        ],
      },
      idle: {
        initial: "noError",
        on: {
          SET_DOCUMENT: [
            {
              cond: "hasSavedDoc",
              actions: "assignUploadedDoc",
              target: "showingWarning",
            },
            {
              actions: "assignUploadedDoc",
              target: "fetchingUrl",
            },
          ],
          RESUME: {
            target: "fetchingSavedDocInfo",
          },
        },
        states: {
          noError: {
            entry: "clearError",
          },
          errored: {
            on: {
              NAV_AWAY: {
                actions: ["clearError", "resetContext"],
                target: "#fetchingSavedDoc",
              },
            },
          },
        },
      },
      fetchingSavedDocInfo: {
        invoke: [
          {
            src: "getSavedDocInfo",
            onDone: {
              actions: [
                "assignPageUrls",
                "assignAllSentences",
                "assignSentences",
              ],
              target: "finishing.reviewing",
            },
            onError: {
              actions: "assignError",
              target: "idle.errored",
            },
          },
        ],
      },
      showingWarning: {
        on: {
          CANCEL: {
            actions: "clearUploadedDoc",
            target: "idle.noError",
          },
          RESUME: {
            target: "fetchingUrl",
          },
        },
      },
      fetchingUrl: {
        on: {
          CANCEL: {
            target: "idle.noError",
          },
        },
        invoke: [
          {
            src: "getUploadUrl",
            onDone: {
              cond: "hasResponse",
              actions: "assignUploadUrl",
              target: "posting",
            },
            onError: {
              actions: "assignError",
              target: "idle.errored",
            },
          },
        ],
      },
      posting: {
        on: {
          CANCEL: {
            target: "idle.noError",
          },
        },
        invoke: [
          {
            src: "postLargeDoc",
            onDone: {
              cond: "hasResponse",
              target: "fetchingDoc",
            },
            onError: {
              actions: "assignError",
              target: "idle.errored",
            },
          },
        ],
      },
      fetchingDoc: {
        on: {
          CANCEL: {
            target: "idle.noError",
          },
        },
        invoke: [
          {
            src: "createDoc",
            onDone: {
              cond: "hasResponse",
              actions: [
                "assignPageUrls",
                "assignAllSentences",
                "assignSentences",
              ],
              target: "finishing.previewing",
            },
            onError: {
              actions: "assignError",
              target: "idle.errored",
            },
          },
        ],
      },
      finishing: {
        on: {
          NAV_AWAY: {
            actions: "resetContext",
            target: "fetchingSavedDoc",
          },
          CANCEL: {
            actions: "resetContext",
            target: "fetchingSavedDoc",
          },
        },
        initial: "idle",
        states: {
          idle: {},
          previewing: {},
          reviewing: {},
        },
      },
    },
  },
  {
    services: {
      getSavedDoc: async (ctx) => {
        try {
          const results = await ctx.client.mutate({
            mutation: GET_SAVED_DOCUMENTS,
            variables: {
              id: localStorage.getItem("id"),
            },
          });
          return results.data;
        } catch (error) {
          return Promise.reject(error);
        }
      },
      getSavedDocInfo: async (ctx) => {
        const { savedDoc } = ctx;
        try {
          const results = await ctx.client.mutate({
            mutation: GET_DOC,
            variables: {
              id: savedDoc?.document_id,
            },
          });
          return results.data;
        } catch (error) {
          return Promise.reject(error);
        }
      },
      getUploadUrl: async (ctx) => {
        const { uploadedDoc } = ctx;
        try {
          const results = await ctx.client.mutate({
            mutation: GET_UPLOAD_URL,
            variables: {
              document_id: uploadedDoc?.document_id,
              document_name: uploadedDoc?.document_name,
            },
          });
          return results.data;
        } catch (error) {
          return Promise.reject(error);
        }
      },
      postLargeDoc: async (ctx) => {
        const { uploadedDoc, uploadUrl } = ctx;
        try {
          const buffer =
            uploadedDoc && (await uploadedDoc.document.arrayBuffer());
          let byteArray = buffer && new Int8Array(buffer);

          const requestOptions = {
            method: "PUT",
            body: byteArray,
          };

          uploadUrl && (await fetch(uploadUrl, requestOptions));
        } catch (error) {
          return Promise.reject(error);
        }
      },
      createDoc: async (ctx) => {
        const { uploadedDoc } = ctx;
        try {
          const results = await ctx.client.mutate({
            mutation: CREATE_DOC,
            variables: {
              doc_id: uploadedDoc?.document_id,
            },
          });
          return results.data;
        } catch (error) {
          return Promise.reject(error);
        }
      },
    },
    actions: {
      assignSavedDoc: actions.assign((_ctx, event) => {
        let hasSavedDoc = false;
        let savedDoc: SavedDoc | null = null;
        if (event.data.user.active_document !== null) {
          hasSavedDoc = true;

          const response = event.data.user.active_document;
          savedDoc = {
            document_name: response.filename,
            document_id: response.id,
            creation_time: new Date(response.creation_time),
            last_edit_time: new Date(response.last_edit_time),
          };
        }
        return { hasSavedDoc: hasSavedDoc, savedDoc: savedDoc };
      }),
      assignUploadedDoc: actions.assign((_ctx, event) => {
        const uploadedDoc = {
          document_id: event.document_id,
          document_name: event.document_name,
          document_size: event.document_size,
          document: event.document,
        };
        return { uploadedDoc: uploadedDoc };
      }),
      assignUploadUrl: actions.assign((_ctx, event) => {
        return { uploadUrl: event.data.createS3PostUrl };
      }),
      assignPageUrls: actions.assign((_ctx, event) => {
        const response = getPages(event);
        const pageUrls = response.map((pageData: GraphQlPage) => {
          return {
            id: pageData.id,
            s3_raw_url: pageData.s3_raw_url,
            s3_ocr_url: pageData.s3_ocr_url,
          };
        });
        return {
          pageUrls: pageUrls,
          totalPages: pageUrls.length,
        };
      }),
      assignAllSentences: actions.assign((ctx, event) => {
        const allSentences = setSentences(ctx, event, false);
        return { allSentences: allSentences };
      }),
      assignSentences: actions.assign((ctx, event) => {
        const sentences = setSentences(ctx, event, true);
        return { sentences: sentences };
      }),
      assignError: actions.assign((_ctx, event) => {
        return { error: event };
      }),
      clearUploadedDoc: actions.assign((_ctx, event) => {
        return { uploadedDoc: null };
      }),
      clearError: actions.assign((_ctx, _event) => {
        return { error: null };
      }),
      resetContext: actions.assign((_ctx, _event) => {
        return {
          hasSavedDoc: null,
          savedDoc: null,
          uploadedDoc: null,
          uploadUrl: null,
          curPageNumber: 0,
          totalPages: 0,
          pageUrls: [],
          curSentenceNumber: 0,
          allSentences: [],
          sentences: [],
          error: null,
        };
      }),
    },
    guards: {
      hasSavedDoc: (ctx, event) => {
        return Boolean(ctx.hasSavedDoc);
      },
      hasResponse: (ctx, _event) => {
        return Boolean(ctx.pageUrls) && Boolean(ctx.sentences);
      },
    },
  }
);

const getPages = (event: any) => {
  let pages;
  if ("uploadDocument" in event.data) {
    pages = event.data.uploadDocument.pages;
  } else if ("createDocumentFromID" in event.data) {
    pages = event.data.createDocumentFromID.pages;
  } else if ("user" in event.data) {
    pages = event.data.user.active_document.pages;
  } else if ("document" in event.data) {
    pages = event.data.document.pages;
  }

  return pages;
};

// https://www.geeksforgeeks.org/how-to-remove-duplicates-from-an-array-of-objects-using-javascript/
const removeDuplicateSentences = (sentences: GraphQlSentence[]) => {
  // Declare a new array
  let newArray = [];

  // Declare an empty object
  let uniqueObject: any = {};

  // Loop through the array elements
  for (const i in sentences) {
    // Extract the text
    const text = sentences[i]["text"];

    // Use the text as the index
    uniqueObject[text] = sentences[i];
  }

  // Loop to push unique object into array
  for (const i in uniqueObject) {
    newArray.push(uniqueObject[i]);
  }

  // Display the unique objects
  return newArray;
};

const setSentences = (ctx: any, event: any, onlyProblematic: boolean) => {
  const { pageUrls } = ctx;
  const response = getPages(event);

  let sentences: any = [];
  for (let i = 0; i < response.length; i++) {
    sentences[i] = [];
    const filteredSentences = removeDuplicateSentences(
      response[i]["sentences"]
    );
    filteredSentences.forEach((sentenceData: GraphQlSentence) => {
      const rects = sentenceData.rectangles.map(
        (rectangle: GraphQlRectangle) => {
          return {
            x1: rectangle.x0,
            y1: rectangle.y0,
            x2: rectangle.x1,
            y2: rectangle.y1,
            width: 809.9999999999999,
            height: 1200,
          };
        }
      );

      const boundingRect = {
        x1: Math.min(...rects.map((rect) => rect.x1)),
        y1: Math.min(...rects.map((rect) => rect.y1)),
        x2: Math.max(...rects.map((rect) => rect.x2)),
        y2: Math.max(...rects.map((rect) => rect.y2)),
        width: 809.9999999999999,
        height: 1200,
      };

      const newSentence = {
        isActive: false,
        id: sentenceData.id,
        page_id: pageUrls[i]["id"],
        content: { text: sentenceData.text },
        position: {
          // Each page of a doc is its own PDF
          pageNumber: 1,
          usePdfCoordinates: true,
          boundingRect: boundingRect,
          rects: rects,
        },
        info: {
          user_defined: false,
          model_problematic: sentenceData.problematic,
          user_problematic: UserProblematic.UNREVIEWED,
          severity_score: SeverityScore.UNREVIEWED,
        },
      };

      if (sentenceData.feedback) {
        const { info } = newSentence;

        const severityScore = setSeverityScore(
          sentenceData.feedback?.severity_score
        );
        const userProblematic = setUserProblematic(severityScore);

        info.user_defined = sentenceData.feedback?.user_defined || false;
        info.model_problematic = sentenceData.problematic;
        info.severity_score = severityScore;
        info.user_problematic = userProblematic;
      }

      sentences[i].push(newSentence);
      if (onlyProblematic === true && sentenceData.problematic === false) {
        sentences[i].pop();
      }
    });
  }
  return sentences;
};

const setSeverityScore = (severity_score: string | undefined) => {
  if (severity_score === null) {
    return SeverityScore.UNREVIEWED;
  } else if (severity_score === "MID") {
    return SeverityScore.MEDIUM;
  } else {
    return SeverityScore[severity_score as keyof typeof SeverityScore];
  }
};

const setUserProblematic = (severity_score: string | undefined) => {
  const isProblematic = [
    SeverityScore.HIGH,
    SeverityScore.MEDIUM,
    SeverityScore.LOW,
  ];
  if (
    (severity_score &&
      isProblematic.includes(
        SeverityScore[severity_score as keyof typeof SeverityScore]
      )) ||
    severity_score === "MID"
  ) {
    return UserProblematic.YES;
  } else {
    return UserProblematic[severity_score as keyof typeof UserProblematic];
  }
};
